import React, { useState, Fragment, useEffect, useCallback } from 'react'
import './index.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Autocomplete from '@mui/material/Autocomplete';
import { Button, FormControlLabel, Grid, TextField, Box } from '@mui/material';

import YearFilter from '../../YearFilter';
import CircularProgress from '@mui/material/CircularProgress';

import { axiosRequest } from '../../../services/utils/axios';

import axios from 'axios';
import { API_BASE } from '../../../constants';

import Chip from '@mui/material/Chip';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';
const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: red[600],
        '&:hover': {
            backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: red[600],
    },
  }));
const VisualisationFilterComponent = ({ numberOfTiers,supplier_companies, customer_companies, countries, handleFilterSelection,  }) => {


    const [filterStatus, setFilterStatus] = useState("draft") // draft or applied

    const [expandFilter, setExpandFilter] = useState(true)
    
    const [currentSearchableSuppliers, setCurrentSearchableSuppliers] = useState()
    const [currentSearchableCustomers, setCurrentSearchableCustomers] = useState()
    const [currentSearchableCountries, setCurrentSearchableCountries] = useState()

    const [supplierSearchResults, setSupplierSearchResults] = useState(currentSearchableSuppliers ? currentSearchableSuppliers : []);
    const [customerSearchResults, setCustomerSearchResults] = useState(currentSearchableCustomers ? currentSearchableCustomers : []);
    const [countrySearchResults, setCountrySearchResults] = useState([]);

    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        const supplier_filteredDictList = supplier_companies?.filter(({ tier }) => tier <= 3);
        const supplier_selectedTier = [...new Set(supplier_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableSuppliers(supplier_selectedTier)
        setSupplierSearchResults(supplier_selectedTier)
        const customer_filteredDictList = customer_companies?.filter(({ tier }) => tier <= 3);
        const customer_selectedTier = [...new Set(customer_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableCustomers(customer_selectedTier)
        setCustomerSearchResults(customer_selectedTier)
        const fcountries = countries.filter((c) => c!=="")
        setCurrentSearchableCountries(fcountries)

    }, [supplier_companies, customer_companies, countries]);

    const [supplierFilters, setSupplierFilters] = useState({
        draft: {
            tier: numberOfTiers, countries: [], suppliers: [], customers: [], is_sus: false, show_suppliers: true, show_customers: true
        },
        applied: {
            tier: numberOfTiers, countries: [], suppliers: [], customers: [], is_sus: false, show_suppliers: true, show_customers: true
        },
    })

    const handleTierChange = (event) => {
        const selectedTier = event.target.value
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], tier: selectedTier } }
        setSupplierFilters(filters)
        const supplier_filteredDictList = supplier_companies?.filter(({ tier }) => tier <= selectedTier);
        const supplier_selectedTier = [...new Set(supplier_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableSuppliers(supplier_selectedTier)
        
        const customer_filteredDictList = customer_companies?.filter(({ tier }) => tier <= selectedTier);
        const customer_selectedTier = [...new Set(customer_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableCustomers(customer_selectedTier)
    }

    const handleTierDeletion = (_event) => {
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], tier: "" } }

        setSupplierFilters(filters)
        const supplier_filteredDictList = supplier_companies?.filter(({ tier }) => tier <= 1);
        const supplier_selectedTier = [...new Set(supplier_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableSuppliers(supplier_selectedTier)
        
        const customer_filteredDictList = customer_companies?.filter(({ tier }) => tier <= 1);
        const customer_selectedTier = [...new Set(customer_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableCustomers(customer_selectedTier)
    }

    const handleCountryDeletion = (countryToBeDeleted) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                countries: supplierFilters[filterStatus].countries.filter((country) => country !== countryToBeDeleted)
            }
        }
        setSupplierFilters(filters)
    }


    const handleSuppliersChange = (_event, selectedSuppliers) => {
        console.log("selectedSuppliers", selectedSuppliers)
        const newSelectedCountries = [...new Set([...selectedSuppliers])]
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], suppliers: newSelectedCountries } }
        setSupplierFilters(filters)
    };
    const handleCustomersChange = (_event, selectedCustomers) => {
        console.log("selectedSuppliers", selectedCustomers)
        const newSelectedCountries = [...new Set([...selectedCustomers])]
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], customers: newSelectedCountries } }
        setSupplierFilters(filters)
    };
    const handleCountriesChange = (_event, selectedCountries) => {
        console.log("selectedSuppliers", selectedCountries)
        const newSelectedCountries = [...new Set([...selectedCountries])]
        const filters = { ...supplierFilters, [filterStatus]: { ...supplierFilters[filterStatus], countries: newSelectedCountries } }
        setSupplierFilters(filters)
    };
    const handleSupplierDeletion = (supplierToBeDeleted) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                suppliers: supplierFilters[filterStatus].suppliers.filter((supplier) => supplier !== supplierToBeDeleted)
            }
        }
        setSupplierFilters(filters)
    }
    const handleCustomerDeletion = (supplierToBeDeleted) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                customers: supplierFilters[filterStatus].customers.filter((supplier) => supplier !== supplierToBeDeleted)
            }
        }
        setSupplierFilters(filters)
    }

    const handleSupplierInputChange = (event) => {
        const searchTerm = event.target.value
        setSearchLoading(true)
        console.log(currentSearchableSuppliers);
        if(searchTerm === "") {
            setSupplierSearchResults(currentSearchableSuppliers);
        } else {
            const filteredCompanies = currentSearchableSuppliers.filter(company => searchTerm && company.toLowerCase().startsWith(searchTerm.toLowerCase()));
            setSupplierSearchResults(filteredCompanies);
        }
        setSearchLoading(false)
    }
    const handleCustomerInputChange = (event) => {
        const searchTerm = event.target.value
        setSearchLoading(true)
        console.log(currentSearchableSuppliers);
        const filteredCompanies = currentSearchableCustomers.filter(company => searchTerm && company.toLowerCase().startsWith(searchTerm.toLowerCase()));
        setCustomerSearchResults(filteredCompanies);
        setSearchLoading(false)
    }
    const handleCountryInputChange = (event) => {
        const searchTerm = event.target.value
        setSearchLoading(true)
        if(searchTerm.length == 0) {
            setCountrySearchResults(currentSearchableCountries);
            setSearchLoading(false)
        } else {
            const filteredCountries = currentSearchableCountries.filter(country => searchTerm && country.toLowerCase().startsWith(searchTerm.toLowerCase()));
            setCountrySearchResults(filteredCountries);
            setSearchLoading(false)
        }
        
    }
    const [is_sus, setIsSus] = useState(false);

    const setSus = () => {
      setIsSus((prevSuspiciousOnly) => {
          return !prevSuspiciousOnly;
      });
    }; 
    const [is_show_supplier, setIsShowSupplier] = useState(true);

    const setShowSupplier = () => {
        setIsShowSupplier((prev) => {
          return !prev;
      });
    }; 
    const [is_show_customer, setIsShowCustomer] = useState(true);

    const setShowCustomer = () => {
        setIsShowCustomer((prev) => {
          return !prev;
      });
    }; 
    const handleFilterCancel = () => {
        setExpandFilter(false)
        setFilterStatus("draft")
        const filters = { ...supplierFilters, draft: { ...supplierFilters["applied"] } }
        setSupplierFilters(filters)
        const supplier_filteredDictList = supplier_companies?.filter(({ tier }) => tier <= 1);
        const supplier_selectedTier = [...new Set(supplier_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableSuppliers(supplier_selectedTier)
        
        const customer_filteredDictList = customer_companies?.filter(({ tier }) => tier <= 1);
        const customer_selectedTier = [...new Set(customer_filteredDictList?.flatMap(({ shipperName }) => shipperName))];
        setCurrentSearchableCustomers(customer_selectedTier)
    }

    const handleApplyFilter = () => {
        // TODO call API to apply filter
        setExpandFilter(false)
        const filters = { ...supplierFilters, applied: { ...supplierFilters["draft"] } }
        setSupplierFilters(filters)
        setFilterStatus("applied")
        handleFilterSelection(supplierFilters["draft"])
    }
    useEffect(()=> {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                is_sus: is_sus
            }
        }
        setSupplierFilters(filters)
    },[is_sus]);
    useEffect(()=> {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                show_suppliers: is_show_supplier
            }
        }
        setSupplierFilters(filters)
    },[is_show_supplier]);
    useEffect(()=> {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
               show_customers : is_show_customer
            }
        }
        setSupplierFilters(filters)
    },[is_show_customer]);
    return (
        <div className={`${expandFilter ? 'visual-filter-main-expanded-container' : 'visual-filter-main-container'}`}>
            <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                endIcon={expandFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                color="info"
                className='supplier-filters-btn'
                aria-label='Filter options open'
                onClick={() => { setFilterStatus('draft'); setExpandFilter(!expandFilter)}}
            >
                Map Filters
            </Button>
            <div className={`${expandFilter ? 'supplier-filter-secondary-expanded-container' : 'supplier-filter-secondary-container'}`}>
                {
                expandFilter && 
                <div style = {{padding: '2rem'}}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                    multiple
                                    disablePortal
                                    id="supplier-suppliers-filter"
                                    options={supplierSearchResults}
                                    sx={{ width: '100%' }}
                                    value={supplierFilters[filterStatus].suppliers}
                                    loading={searchLoading}
                                    onInputChange={handleSupplierInputChange}
                                    onChange={handleSuppliersChange}
                                    renderTags={() => null}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Search Suppliers"
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                borderRadius: "0px",
                                            },
                                            endAdornment: (
                                                <Fragment>
                                                    {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            )
                                        }}
                                    />
                                    }
                                />
                        </Grid>  
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                    multiple
                                    disablePortal
                                    id="supplier-customers-filter"
                                    options={customerSearchResults}
                                    sx={{ width: '100%' }}
                                    value={supplierFilters[filterStatus].customers}
                                    loading={searchLoading}
                                    onInputChange={handleCustomerInputChange}
                                    onChange={handleCustomersChange}
                                    renderTags={() => null}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Search Customers"
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                borderRadius: "0px",
                                            },
                                            endAdornment: (
                                                <Fragment>
                                                    {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            )
                                        }}
                                    />
                                    }
                                />
                        </Grid>   
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={<Switch checked={is_show_customer} onChange={setShowCustomer} />}
                                label="Show Customers"
                            />
                        </Grid> 
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={<Switch checked={is_show_supplier} onChange={setShowSupplier} />}
                                label="Show Suppliers"
                            />
                        </Grid>  
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ bgcolor: 'white', p: 1, borderRadius: 1, height: '100%', display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                    <RedSwitch
                                        checked={is_sus}
                                        onChange={setSus}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                    label="Potential Supply Chain Risk Only"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl fullWidth>
                                <InputLabel id="supplier-filter-select-label">Tiers</InputLabel>
                                <Select
                                    labelId="supplier-filter-select-label"
                                    id="supplier-filter-select"
                                    value={supplierFilters[filterStatus].tier}
                                    label="Tiers"
                                    onChange={handleTierChange}>
                                    {Array.from({ length: numberOfTiers }, (_, i) => i + 1).map((item) => <MenuItem key={item} value={item}>{`Up to ${item}`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                disablePortal
                                id="supplier-countries-filter"
                                options={countrySearchResults}
                                sx={{ width: '100%' }}
                                value={supplierFilters[filterStatus].countries}
                                loading={searchLoading}
                                onInputChange={handleCountryInputChange}
                                onChange={handleCountriesChange}
                                renderTags={() => null}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Search Countries"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            borderRadius: "0px",
                                        },
                                        endAdornment: (
                                            <Fragment>
                                                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        )
                                    }}
                                />
                                }
                            />
                        </Grid> */}
                    </Grid>
                    </div>
                }
                <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap', pl: '2rem' }}>
                    {
                        supplierFilters[filterStatus]?.tier && <div className='selected-supplier-container'>
                            <Chip
                                color="primary"
                                label={`Tiers | Up to ${supplierFilters[filterStatus]?.tier}`}
                                variant='filled'
                                {...expandFilter ? { onDelete: handleTierDeletion, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.is_sus && <div className='selected-supplier-container'>
                            <Chip
                                color="error"
                                label={`Potential Supply Chain Risk Only`}
                                variant='filled'
                                {...expandFilter ? { onDelete: setSus, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.show_suppliers && <div className='selected-supplier-container'>
                            <Chip
                                color="primary"
                                label={`Showing Suppliers`}
                                variant='filled'
                                {...expandFilter ? { onDelete: setShowSupplier, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.show_customers && <div className='selected-supplier-container'>
                            <Chip
                                color="primary"
                                label={`Showing Customers`}
                                variant='filled'
                                {...expandFilter ? { onDelete: setShowCustomer, variant: "filled" } : {variant: "filled"}}
                            />
                        </div>
                    }
                    {
                        supplierFilters[filterStatus]?.countries?.map(
                            (country) => <div key={country} className='selected-supplier-container'>
                                <Chip key={country}
                                    color="secondary"
                                    label={`Country | ${country}`}
                                    {...expandFilter ? { onDelete: () => handleCountryDeletion(country), variant: "outlined" } : {variant: "outlined"}}
                                />
                            </div>
                        )
                    }
                    {
                        supplierFilters[filterStatus]?.suppliers?.map(
                            (supplier) => <div key={supplier} className='selected-supplier-container'>
                                <Chip key={supplier}
                                    color="success"
                                    label={`Supplier | ${supplier}`}
                                    {...expandFilter ? { onDelete: () => handleSupplierDeletion(supplier), variant: "outlined" } : {variant: "outlined"}}
                                    variant="outlined"
                                />
                            </div>
                        )
                    }
                    {
                        supplierFilters[filterStatus]?.customers?.map(
                            (customer) => <div key={customer} className='selected-supplier-container'>
                                <Chip key={customer}
                                    color="success"
                                    label={`Customer | ${customer}`}
                                    {...expandFilter ? { onDelete: () => handleCustomerDeletion(customer), variant: "outlined" } : {variant: "outlined"}}
                                    variant="outlined"
                                />
                            </div>
                        )
                    }
                </Box>

                {expandFilter && <div className='supplier-apply-filters-btn-container'>
                    <Button
                        className='clear-supplier-filters-btn'
                        aria-label="cancel button"
                        variant="outlined"
                        onClick={handleFilterCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='supplier-apply-filters-btn'
                        aria-label="apply filter"
                        variant="contained"
                        onClick={handleApplyFilter}
                    >
                        Apply Filters
                    </Button>
                </div>}
            </div>
        </div>
    )
}

export default VisualisationFilterComponent;