import { useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-polylinedecorator";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import React, { useEffect } from "react";

import "./index.css";
import { processRelationships } from "./MapHelperFunctions";


const RelationshipMapComponent = ({
  supplier_details,
  customer_details,
  company_name,
  selectedTierFilter,
  selectedSuppliers,
  selectedCustomers
}) => {
  const handleZoomOut = () => {
    if (locMapRef.current) {
      // Check if the map instance exists
      locMapRef.current.setView([0, 0], 2.4); // Set the view to the initial position
    }
  };
  const [loading, setLoading] = useState(false);
  const locMapRef = useRef(null);
  const markerGroupRef = useRef(null);
  const lineGroupRef = useRef(null);
  const decoratorGroupRef = useRef(null);
 
  const plotMap = (from_map, supplier_details, customer_details,) => {
    var sup_tiers = supplier_details ? supplier_details.tiers : [];
    var cus_tiers = customer_details ? customer_details.tiers : [];

    const markerGroup =  markerGroupRef.current; 
    const lineGroup = lineGroupRef.current;
    const decoratorGroup = decoratorGroupRef.current;
    processRelationships(
        sup_tiers,
        selectedTierFilter?.tiers,
        false,
        company_name,
        supplier_details,
        selectedSuppliers,
        lineGroup,
        markerGroup,
        decoratorGroup
    );
    processRelationships(
        cus_tiers,
        selectedTierFilter?.tiers,
        true,
        company_name,
        customer_details,
        selectedCustomers,
        lineGroup,
        markerGroup,
        decoratorGroup
    );

    setLoading(false);
    markerGroupRef.current.addTo(from_map);
    lineGroupRef.current.addTo(from_map);
    decoratorGroupRef.current.addTo(from_map);
  };
  useEffect(()=> {
    let locMap = locMapRef.current;
    if(locMap) {
      locMap.setView(
        [supplier_details?.company?.latitude || 0, supplier_details?.company?.longitude || 0],
          3
      )
    }
  },[supplier_details])
  
  useEffect(() => {
    setLoading(true);
    let locMap = locMapRef.current;
    if (!locMap) {
      console.log(supplier_details?.company?.latitude,"safe check", supplier_details)
      locMap = L.map('supplier-map-unique-id').setView(
          [supplier_details?.company?.latitude || 0, supplier_details?.company?.longitude || 0],
          7
      );

      L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          {
              attribution:
                  'Map tiles by <a href="https://carto.com/">Carto</a>, ' +
                  'data by <a href="https://openstreetmap.org/">OpenStreetMap</a>',
              maxZoom: 18,
          }
      ).addTo(locMap);

      const maxBounds = [
          [-90, -180], // Southwest corner (latitude, longitude)
          [90, 180], // Northeast corner (latitude, longitude)
      ];

      locMap.setMaxBounds(maxBounds);

      locMap.dragging.enable();
      locMapRef.current = locMap;
      }
     
      if (markerGroupRef.current) {
        markerGroupRef.current.clearLayers();
      } else {
          markerGroupRef.current = L.layerGroup(); // No addTo here
      }
      if(decoratorGroupRef.current) {
        decoratorGroupRef.current.clearLayers();
      } else {
        decoratorGroupRef.current = L.layerGroup();
      }
      if (lineGroupRef.current) {
        lineGroupRef.current.clearLayers();
      } else {
          lineGroupRef.current = L.layerGroup(); // No addTo here
      }

      plotMap(locMap, supplier_details, customer_details);
      
      locMap.on("zoom", function () {
        const currentZoom = locMap.getZoom();

        const slimLineZoomThreshold = 1;
        if (currentZoom < slimLineZoomThreshold) {
          locMap.removeLayer(lineGroupRef.current);
          locMap.removeLayer(decoratorGroupRef.current);
        } else {
          locMap.addLayer(lineGroupRef.current);
          locMap.addLayer(decoratorGroupRef.current);
        }
      });
      
    
  }, [selectedTierFilter, supplier_details, customer_details]);

  return (
    <>
      <div className="map-component-container">
      {loading ? <CircularProgress />
          :<div></div>}
          <div className="map-reset-section">
                
                <Button
                  className="map-reset-zoom-button"
                  variant="outlined"
                  aria-label="zoom out map to original size button"
                  startIcon={<ZoomOutMapIcon />}
                  onClick={handleZoomOut}
                >
                  Zoom Out
                </Button>
               
               
        </div>
        
        {
          !loading && (
            <div
              className="supplier-map-plot"
              id="supplier-map-unique-id"
              style={{ height: "400px" }}
            ></div> 
          )
        }  
      </div>
    </>
  );
};

export default RelationshipMapComponent;
