import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ProfileDropDown from "../ProfileDropDown";
import { getUserThunk } from "../../services/user-thunk";

import "./index.css";

function NavbarComponent() {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.user);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  // const [profileOpen, setProfileOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleProfileOpen = (event) => {
  //   setProfileOpen(true);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleProfileClose = () => {
  //   setAnchorEl(null);
  //   setProfileOpen(false);
  // };

  const handleLogout = () => {
    try {
      if (window.umami) {
        window.umami.track('userLogout', {
          "userId": currentUser?.id,
          "logoutTimestamp": new Date().toISOString()
        });
      } else {
        console.log("window.umami not there");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    localStorage.removeItem("accessToken");
    dispatch(getUserThunk());
  };

  const handleToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    localStorage.setItem('redirectPath', window.location.pathname); // store the current path in local storage so that we can redirect back to it after login
    navigate('/login');
  };

  return (
    <Navbar
      bg="dark"
      expand="md" // collapsible navbar on medium/small screens
      fixed="top"
      className="top-nav"
      expanded={navbarExpanded} // collapsed/expanded state on medium/small screens
    >
      <div className={`${navbarExpanded ? "nav-container-expanded" : "nav-container"}`}>

        <Navbar.Brand href="/">
          <div className="logo-style">
            <img
              width={220}
              src={require(".//ST_OneLine_neg_RGB.png")}
              alt="logo"
              tabIndex={0}
            />
          </div>
        </Navbar.Brand>

        {/* only for small/medium screens */}
        <Navbar.Toggle
          aria-controls="navbarNavDropdown"
          onClick={handleToggle}
          className={`custom-toggler ${navbarExpanded ? "cross-icon" : ""}`}
        />
        
        {/* on small/medium screens this will be a collapsible component*/}
        <Navbar.Collapse id="navbarNavDropdown" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link
              href="/"
              className={`main-link ${location.pathname === "/" ? `main-link-active disabled` : ``
                } ${navbarExpanded ? "centred" : ""}`}
            >
              Home
            </Nav.Link>
            <NavDropdown title="About" id="navbarDropdownMenuLink" className={`${navbarExpanded ? "centred" : ""}`}>
              <NavDropdown.Item href="https://info.supplytrace.org/mission/" >
                Our Mission
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/governance/">
                Governance
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/supporters/">
                Supporters
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="People" id="navbarDropdownMenuLink" className={`${navbarExpanded ? "centred" : ""}`}>
              <NavDropdown.Item href="https://info.supplytrace.org/our-team/">
                Our Team
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/advisory-board/">
                Advisory Board
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Resources" id="navbarDropdownMenuLink" className={`${navbarExpanded ? "centred" : ""}`}>
              <NavDropdown.Item href="https://info.supplytrace.org/methodology/">
                Methodology
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/guides/">
                Guides
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/faqs/">
                FAQs
              </NavDropdown.Item>
              <NavDropdown.Item href="https://info.supplytrace.org/media-hub/">
                Media Hub
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="https://info.supplytrace.org/contact-us/"
              className={`main-link ${navbarExpanded ? "centred" : ""}`}
            >
              Contact Us
            </Nav.Link>
            {!currentUser?.username && (
              <Nav.Link href="/login" className="main-link" onClick={handleLoginClick}>
                Login
              </Nav.Link>
            )}
            {currentUser?.username && (
              <NavDropdown
                title={currentUser.username}
                id="navbarDropdownMenuLink"
                className={`${navbarExpanded ? "centred" : ""}`}
              >
                <NavDropdown.Item
                  onClick={handleLogout}
                  className="color-red"
                  href="/login"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>

      </div>
    </Navbar>
  );
}

export default NavbarComponent;
