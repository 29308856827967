import { useRef, useState } from "react";
import L, { map } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-polylinedecorator";
// import CircularExample from "./CircularExample";
import SankeyChart from "./SankeyChart";
import CircularProgress from '@mui/material/CircularProgress';

import React, { useEffect } from "react";

import "./index.css";
const getColor = (level) => {
  if(level===0) {
    return 'red';
  } else if(level===1) {
    return 'orange';
  } else if(level===2) {
    return 'yellow';
  } else {
    return 'green';
  }
};
const plot_line_color = (from_id, to_id, suspicious_level_dictionary) => {
    
  var from_level = -1;
  var to_level = -1;
  var color_tobe_filled = "steelblue";
  for (
    var i = 0;
    i < Object.keys(suspicious_level_dictionary).length;
    i++
  ) {
    var hasFromCompany = suspicious_level_dictionary[i].some(function (
      company
    ) {
      return (
        from_id === company
        // ||from_id.includes(company_name)
      );
    });
    var hasToCompany = suspicious_level_dictionary[i].some(function (
      company
    ) {
      return (
        to_id === company // || to_id.includes(company_name)
      );
    });
    if (hasFromCompany && from_level===-1) {
      from_level = i;
    }
    if (hasToCompany && to_level===-1) {
      to_level = i;
    }
    if (from_level !== -1 && to_level !== -1) {
      var k = Math.min(from_level, to_level);
      if (k === 0) {
        color_tobe_filled = "red";
      } else if (k === 1) {
        color_tobe_filled = "orange";
      } else if (k === 2) {
        color_tobe_filled = "yellow";
      }
      break;
    }
  }
  return [from_level, to_level, color_tobe_filled];
}
const RelationshipSankeyComponent = ({
  supplier_details,
  customer_details,
  company_name,
  selectedTierFilter
}) => {
  const [loading, setLoading] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(null);
  const [teardown, setTeardown] = useState(false);
  const plotSankey = (supplierDetails, customerDetails) => {
      const allDetails = [supplierDetails, customerDetails];
      var sankeyDisplayData = {
          "nodes": [],
          "links": []
      };
      const uniqueLinkSet = new Set();

      allDetails.forEach(details => {
          if(!details) {
            return;
          }
          var tiers = details.tiers;

          for (let tierIndex = 1; tierIndex <= tiers.length; tierIndex++) {
              const tier = tiers[tierIndex-1];
              var relationships = details === supplierDetails ? tier.from_relations : tier.to_relations;
              if (tierIndex > parseInt(selectedTierFilter, 10)) {
                  break;
              }
              relationships.forEach((relationship) => {
                  const fromCompanyId = relationship.from_company__id;
                  const toCompanyId = relationship.to_company__id;
                  if (fromCompanyId == null || toCompanyId == null) {
                      return;
                  }
                  // Check if this combination has been added before
                  const linkKey = `${fromCompanyId}-${toCompanyId}`;
                  if (uniqueLinkSet.size > 60) {
                      setTeardown(true);
                  }
                  if (!uniqueLinkSet.has(linkKey) && (uniqueLinkSet?.size < 61)) {
                      // Add the combination to the set to ensure uniqueness
                      uniqueLinkSet.add(linkKey);

                      const link = {
                          "source": fromCompanyId,
                          "target": toCompanyId,
                          "value": 10, // Use total_transactions directly or set a default value (e.g., 1)
                          "total_transactions": relationship.total_transactions
                      };

                      if (fromCompanyId !== toCompanyId) {
                          sankeyDisplayData.links.push(link);
                      }
                      const [from_level, to_level, _] = plot_line_color(relationship.from_company__id, relationship.to_company__id, details?.sus_relations);

                      sankeyDisplayData.nodes.push({
                          name: relationship.from_company__id,
                          category: relationship.from_company__company_name_english,
                          color: relationship.from_company__company_name_english === company_name ? "black" : getColor(from_level)
                      });
                      sankeyDisplayData.nodes.push({
                          name: relationship.to_company__id,
                          category: relationship.to_company__company_name_english,
                          color: relationship.to_company__company_name_english === company_name ? "black" : getColor(to_level)
                      });
                  }
              });
          }
      });

      sankeyDisplayData.nodes = Array.from(new Set(sankeyDisplayData.nodes.map(JSON.stringify))).map(JSON.parse);

      return sankeyDisplayData;
  };



  useEffect(()=> {
    setLoading(true);
    setTeardown(false)
    setDisplayDetails(plotSankey(supplier_details, customer_details));

    setLoading(false);
  },[ supplier_details, customer_details]);

  return (
    <>
      <div className="map-component-container">
      {loading ? <CircularProgress />
          :<div></div>}
        
        {
          !loading && displayDetails && displayDetails?.nodes?.length>0 && (
            <SankeyChart data={displayDetails} width={1000} height={500} />
          )
        }  
        {
          !loading && displayDetails && displayDetails?.nodes?.length===0 && (
            <h4 style={{textAlign: "center"}}> No Relationships Found</h4>
          )
        }  
        {
          !loading && displayDetails && teardown && (
            <h4 style={{textAlign: "center"}}>Some relationships are missing. Please Narrow down your filters</h4>
          )
        }
      </div>
    </>
  );
};

export default RelationshipSankeyComponent;
