import React from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import SupplierModalComponent from './SupplierModalComponent/index';
import CustomerModalComponent from './CustomerModalComponent/index';
const TierContainer = ({
    entityData,
    entityType,
    isModalOpen,
    openModal,
    closeModal,
    selectedTier,
}) => {
    return (
        <div className='tier-container'>
            {entityData?.data?.tiers.map((tier, index) => {
                const isSuspicious = entityType === 'supplier' ? tier.suspicious_suppliers : tier.suspicious_consignee;
                const countries = entityType === 'supplier' ? tier.supplier_countries : tier.receiver_countries;
                const entities = entityType === 'supplier' ? tier.shippers : tier.consignee;

                return (
                    <div className='tier-card' key={index}>
                        <div className={`tier-number ${isSuspicious ? 'tier-number-red' : ''}`}>
                            {`Tier ${tier.tier}`}
                            {isSuspicious && (
                                <Tooltip
                                    title="Connections to High-Risk Entities are found in this tier"
                                    placement="right-end"
                                    arrow
                                    style={{ marginLeft: '10px' }}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </div>
                        {countries.length > 0 && (
                            <div className='tier-countries-container'>
                                <div className='tier-countries-header'>Countries</div>
                                <div className='tier-countries'>
                                    {countries.split(',').slice(0, 20).map((country, index) => (
                                        <Chip className='tier-country' label={country} color="primary" variant="filled" key={country} />
                                    ))}
                                    {countries.split(',').length > 20 && (
                                        <Chip
                                            className='tier-country'
                                            label={`+${countries.split(',').length - 20}`}
                                            color="primary"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className='tier-suppliers-container'
                            style={{ paddingTop: countries.length === 0 ? '30px' : '10px' }}
                        >
                            <div className='tier-suppliers-header'>
                                {entityType === 'supplier' ? 'Suppliers' : 'Customers'}
                            </div>
                            <div className='tier-suppliers'>
                                {entities.slice(0, 2).map(({ company_name_english, full_address, suspicious }) => (
                                    <div className='tier-supplier-container' key={company_name_english}>
                                        <div style={{ color: suspicious ? 'red' : 'black' }}>
                                            {company_name_english}
                                        </div>
                                        <div className='tier-supplier-address'>{full_address}</div>
                                    </div>
                                ))}
                                {entities.length > 2 && (
                                    <div className='tier-supplier-more-text'>
                                        {`...and ${entities.length - 2} more`}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            className='learn-more-btn'
                            aria-label={`Click this button to learn more about the ${tier.tier} tier`}
                            onClick={() => openModal(tier)}
                        >
                            Learn More
                        </Button>
                    </div>
                );
            })}
            {isModalOpen && entityType === 'supplier' && (
                <SupplierModalComponent
                    open={isModalOpen}
                    handleClose={closeModal}
                    tier={selectedTier}
                />
            )}
            {isModalOpen && entityType === 'customer' && (
                <CustomerModalComponent
                    open={isModalOpen}
                    handleClose={closeModal}
                    tier={selectedTier}
                />
            )}
        </div>
    );
};

export default TierContainer;
