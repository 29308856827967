import React, { useState } from 'react';
import './index.css'; // Import your CSS file

const RedactLogin = ({company_name}) => {
    const handleLogin = () => {
        window.location.href = "/login"
    };
    return (
        <div className="login-overlay">
          <div className="login-form">
            <div className="login-header" >
                    <img
                        width={600}
                        src="/logo/ST_OneLine_RGB.png"
                        alt="our logo"
                        
                    ></img>
                    <h3 className="redactlogin-logo-tagline">Open supply chain intelligence for a just world</h3>
            </div>
            <h2>Sign in to trace {company_name}</h2>
            <button className="login-button" onClick={handleLogin}>Sign in</button>
            <div className="divider">or</div>
            <p>New to Supply Trace? <a href="/register">Join now</a></p>
          </div>
        </div>
    );
};

export default RedactLogin;
