import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { Grid, TextField, Button } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";

import { sendOTP, login, validateOtp } from "../../services/login-service";
import { getUserThunk } from "../../services/user-thunk";

import "./login.css";

const VerifyUser = ({setIsAuthenticated}) => {

  const { email: encodedEmail } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState(false);
  const [loginInput, setLoginInput] = useState({username: ''});
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { userVerified, credentials } = useSelector(state => state.auth);

  useEffect(() => {
    console.log(encodedEmail,"skskk")
    if (encodedEmail) {
      const decodedEmail = decodeURIComponent(encodedEmail);
      setLoginInput({
        ...loginInput,
        'username': encodedEmail,
      })
      console.log(loginInput)
      console.log('Decoded Email:', decodedEmail);
    } else {
      console.log('No email provided in the URL');
    }
  }, [encodedEmail]);

  const autologin = () => {
    const inputs = {
      ...credentials
    };
    if (userVerified) {
      inputs['userVerified'] = true;
    }
    login(inputs)
      .then((response) => {
        setLoginError(false);
        const { access, userId } = response.data;
        localStorage.setItem("accessToken", access);
        dispatch(getUserThunk());
        setIsAuthenticated(true);
        setLoginLoading(false);
        navigate('/');
        try {
          if (window.umami) {
            window.umami.track('userLogin', {
              "userId": userId,
              "loginTimestamp": new Date().toISOString()
            });
          } else {
            console.log("window.umami not there");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      })
      .catch((error) => {
        if (error.response.data?.detail === "User Not Verified") {
          alert("Please Complete Verfication")
          const encodedEmail = encodeURIComponent(inputs['username']);
          navigate(`/user-verification/${encodedEmail}`);
          return;
        }
        setLoginError(error.response.data || 'Unknown error occurred');
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginLoading(true);
    const { username, otp } = loginInput;

    try {
      await validateOtp({ username, otp });
      setLoginError(false);
      autologin();
    } catch (error) {
      setLoginError(error.message || 'Unknown error occurred');
    }
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setLoginInput({
      ...loginInput,
      [name]: newValue,
    });
  };

  const setRegister = (event) => {
    navigate("/register");
  };

  const handleSendOTP = async() => {
    const { username } = loginInput;
    try {
      await sendOTP(username);
      setLoginError(false);
      alert("OTP Sent");
    } catch (error) {
      setLoginError( 'Unknown error occurred');
    }
  };

  return (
    <div className="login-window">

      <Link to="/" className="unstyled-link">
        <div className="login-header" >
          <img
            className="login-page-logo"
            src={require('./logo/ST_OneLine_RGB.png')}
            alt="our logo"
          />
          <h3 className="login-page-logo-tagline">Open supply chain intelligence for a just world</h3>
        </div>
      </Link>

      <div style={{ textAlign: 'left', marginBottom: '1.5rem', color: '#0062b2' }}>
        <h4 >User Verification</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>

         <Grid item xs={12} className="wd-login-item">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                Check your email for a verification code
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendOTP}
                >
                  Resend OTP
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} className="wd-login-item">
            <label htmlFor="verify_otp" style={{margin: 0}}>OTP</label>
            <TextField
              id="verify_otp"
              required
              fullWidth
              aria-label="otp"
              name="otp"
              // label="OTP"
              type="otp"
              onChange={handleInput}
            />
          </Grid>

          {
            loginError && (
              <p style={{color: "red", marginTop: "15px"}}>{loginError.data}</p>
            )
          }

          <Grid item xs={12} className="login-full-width">
            <Button
              className="wd-submit-btn-login"
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </Grid>

        </Grid>
      </form>

      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mt-4">
        <p className="mb-0">Don't have an account?</p>
        <MDBBtn outline className="mx-2" color="danger" onClick={setRegister}>
          Create Account
        </MDBBtn>
      </div>

    </div>
  );
};

export default VerifyUser;
