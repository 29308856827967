import axios from "axios";
import { API_BASE } from "../constants";

/**
 * Fetches dashboard statistics and suspicious company information.
 * 
 * @returns {Promise<Object>} Dashboard data including company, relations, transaction counts, and suspicious company details.
 * @returns {string} response.companies - Total number of companies.
 * @returns {string} response.relations - Total number of relations.
 * @returns {string} response.transactions - Total number of transactions.
 * @returns {Company[]} response.suspicious_companies - List of suspicious companies.
 * 
 * @example
 * // Response example:
 * {
 *   "companies": "3037555",
 *   "relations": "3635430",
 *   "transactions": "4800571",
 *   "suspicious_companies": [
 *     {
 *       "id": 3293846,
 *       "company_name_english": "XYZ Textile Co., Ltd.",
 *       "brand": "\u5c71\u...",
 *       "identifier": "913206007431271330",
 *       "suspicious": true,
 *       "latitude": 50,
 *       "longitude": 115,
 *       "full_address": "140, Xyz Rd, ...",
 *       "transactions_involved": 105,
 *       "company_name_legal": "\u534e\u5b5a\u65f...",
 *       "source": "investigation",
 *       "investigation_status_id": 2,
 *       "country_id": 46,
 *       "geocode_level": "100",
 *       "visible": true
 *     }
 *   ]
 * }
 */
export const get_details = async () => {
    const response = await axios.get(`${API_BASE}/dashboardNew/`);
    return response;
}

/**
 * Searches for companies based on a query string.
 *
 * @param {string} inputQuery - The search term for finding companies.
 * 
 * @returns {Object} response - The response object.
 * @returns {Array} response.results - An array of search results, each containing the company ID and name.
 *
 * @example
 * // Response example:
 * {
 *   "results": [
 *     [2163033, "Jiangsu Guotai International Group Co., Ltd."],
 *     [3294951, "Zhejiang Shengtai Garment Group Co., Ltd."],
 *     [3281356, "Texhong International Group Ltd."],
 *     [3281195, "Beijing Guanghua Textile Group Co., Ltd."],
 *     [3281391, "Shandong Lanyan Textiles Co., Ltd."]
 *   ]
 * }
 */
export const searchCompanies = async (inputQuery) => {
    const response = await axios.get(`${API_BASE}/searchcompaniesNew?search=${inputQuery}`);
    return response.data;
};

/**
 * Searches for companies using advanced search parameters.
 *
 * @param {Object} params - The query parameters for the search.
 * @param {number} params.limit - The maximum number of results to return.
 * @param {number} params.offset - The starting point for results (pagination).
 * @param {string} params.search - The search term for finding companies.
 * @param {string} params.searchMethod - The search method (e.g., "contains").
 * 
 * @returns {Object} response - The response object.
 * @returns {number} response.count - The total number of matching companies.
 * @returns {Array} response.results - An array of matching companies.
 * @returns {number} response.results[].id - The company ID.
 * @returns {string} response.results[].full_address - The full address of the company.
 * @returns {string} response.results[].company_name_english - The company's English name.
 * @returns {boolean|null} response.results[].suspicious - Whether the company is suspicious (null, true, or false).
 *
 * @example
 * // Response example:
 * {
 *   "count": 2,
 *   "results": [
 *     {
 *       "id": 63365848,
 *       "full_address": "NO 270 JETPAR ROAD AT RANGPAMORBI GJ 363642 INDIA",
 *       "company_name_english": "WALLMARK CERAMIC INDUSTRIAL SURVEY",
 *       "suspicious": null
 *     },
 *     {
 *       "id": 63365848,
 *       "full_address": "NO 270 JETPAR ROAD AT RANGPAMORBI GJ 363642 INDIA",
 *       "company_name_english": "WALLMARK CERAMIC INDUSTRIAL SURVEY",
 *       "suspicious": null
 *     }
 *   ]
 * }
 */
export const advancedSearchCompanies = async (params) => {
    const { limit, offset, search, searchMethod } = params;
    const response = await axios.get(`${API_BASE}/advancesearchcompanies`, {
        params: { limit, offset, search, searchMethod },
    });
    return response;
};

