import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants";

/**
 * Defining common object types in API responses.
 * 
 * // Company Object
 * @typedef {Object} Company
 * @property {number} id - ID of the company.
 * @property {string} company_name_english - English name of the company.
 * @property {?string} brand - Brand name of the company, if available.
 * @property {string} identifier - Identifier of the company, if available.
 * @property {boolean} suspicious - Indicates if the company is suspicious.
 * @property {number} latitude - Latitude of the company location, if available.
 * @property {number} longitude - Longitude of the company location, if available.
 * @property {string} full_address - Full address of the company.
 * @property {number} transactions_involved - Total number of transactions the company is involved in.
 * @property {?string} company_name_legal - Legal name of the company, if available.
 * @property {string} source - Source of the information (e.g., investigation).
 * @property {string} geocode_level - Geocode level.
 * @property {boolean} visible - Visibility status of the company record.
 * @property {?number} investigation_status - Status of investigation.
 * @property {?number} investigation_status_id - (CHECK: is it same as investigation_status)
 * @property {number} country - Country code.
 * @property {?number} country_id - (CHECK: is it same as country)
 * 
 * // Company Object example:
 * @example
 *     {
 *       "id": 3293846,
 *       "company_name_english": "XYZ Textile Co., Ltd.",
 *       "brand": "\u5c71\u...",
 *       "identifier": "913206007431271330",
 *       "suspicious": true,
 *       "latitude": 50,
 *       "longitude": 115,
 *       "full_address": "140, Xyz Rd, ...",
 *       "transactions_involved": 105,
 *       "company_name_legal": "\u534e\u5b5a\u65f...",
 *       "source": "investigation",
 *       "investigation_status_id": 2,
 *       "country_id": 46,
 *       "geocode_level": "100",
 *       "visible": true
 *     }
 * 
 * // Relation Object
 * @typedef {Object} Relation
 * @property {number} id - The relation ID.
 * @property {number} from_company__latitude - The latitude of the 'from' company.
 * @property {number} from_company__id - The ID of the 'from' company.
 * @property {number} to_company__id - The ID of the 'to' company.
 * @property {number} from_company__longitude - The longitude of the 'from' company.
 * @property {number} to_company__latitude - The latitude of the 'to' company.
 * @property {number} to_company__longitude - The longitude of the 'to' company.
 * @property {string} from_company__company_name_english - The English name of the 'from' company.
 * @property {string} to_company__company_name_english - The English name of the 'to' company.
 * @property {string} from_company__full_address - The full address of the 'from' company.
 * @property {string} to_company__full_address - The full address of the 'to' company.
 * @property {boolean} from_company__suspicious - The suspicion flag for the 'from' company.
 * @property {boolean} to_company__suspicious - The suspicion flag for the 'to' company.
 * @property {string} from_company__country__name - The 'from' company's country.
 * @property {string} to_company__country__name - The 'to' company's country.
 * @property {string} total_transactions - The total number of transactions.
 * 
 * // Relation Object example:
 * @example
 *     {
 *       "id": 2804220,
 *       "from_company__latitude": 35,
 *       "from_company__id": 2163033,
 *       "to_company__id": 2135875,
 *       "from_company__longitude": 125,
 *       "to_company__latitude": 40,
 *       "to_company__longitude": -73.9,
 *       "from_company__company_name_english": "XYZ Group Co., Ltd.",
 *       "to_company__company_name_english": "ABC Us",
 *       "from_company__full_address": "Floor 23-F, Plaza Rd, ...",
 *       "to_company__full_address": "96 SPRING Rd NEW YORK, NY 10012, ...",
 *       "from_company__suspicious": true,
 *       "to_company__suspicious": false,
 *       "from_company__country__name": "China",
 *       "to_company__country__name": "United States",
 *       "total_transactions": "5"
 *     }
 * 
 * // Evidence Object
 * @typedef {Object} Evidence
 * @property {string} transaction_date - The date the transaction occurred.
 * @property {string} media_reported_date - The date the media reported the transaction.
 * @property {string} source - The source providing the evidence, usually as HTML content.
 * @property {string} information_source - The origin of the information (e.g., Investigation).
 * 
 * // Evidence Object example:
 * @example
 *     {
 *       "transaction_date": "2022-04-23",
 *       "media_reported_date": "2022-04-23",
 *       "source": "<p>This company has an investment in XYZ ...</p>",
 *       "information_source": "Investigation"
 *     }
 * 
 *  * // Tier Object
 * @typedef {Object} Tier
 * @property {Company[]} shippers - A list of shippers, represented as Company objects.
 * @property {Company[]} consignees - A list of consignees, represented as Company objects.
 * @property {Relation[]} from_relations - A list of relations from this company, represented as Relation objects.
 * @property {Relation[]} to_relations - A list of relations to this company, represented as Relation objects.
 * @property {string} supplier_countries - The countries associated with suppliers.
 * @property {string} receiver_countries - The receiving countries.
 * @property {boolean} suspicious_suppliers - Whether the suppliers are flagged as suspicious.
 * @property {boolean} suspicious_consignee - Whether the consignee is flagged as suspicious.
 * @property {number} tier - The tier level of the data.
 * 
 * // Tier Object example:
 * @example
 *     {
 *       "shippers": [
 *         { // Company   }
 *       ],
 *       "consignees": [
 *         { // Company   }
 *       ],
 *       "from_relations": [
 *         { // Relation  }
 *       ],
 *       "to_relations": [
 *         { // Relation  }
 *       ],
 *       "supplier_countries": "",
 *       "receiver_countries": "Algeria, United States",
 *       "suspicious_suppliers": false,
 *       "suspicious_consignee": false,
 *       "tier": 1
 *     }
 * 
 */

export const get_company_details = async (details) => {
    const response = await axiosRequest.get(`${API_BASE}/loadcompanyinfoNew/${details.company}/${details.year}/${details.productName}/`);
    return response;
}

export const get_all_companies = async () => {
    const response = await axiosRequest.get(`${API_BASE}/getAllCompaniesNew/`);
    return response;
}

export const get_investigate_company_results = async (input) => {
    const response = await axiosRequest.get(`${API_BASE}/loadInputsNew`);
    return response;
}

/**
 * Fetches information for a specific company by company ID.
 *
 * @param {number} companyId - The ID of the company to retrieve.
 * 
 * @returns {Company} response - A Company object.
 * 
 * @example
 * // Response example:
 *     {
 *       "id": 3293846,
 *       "company_name_english": "XYZ Textile Co., Ltd.",
 *       "brand": "\u5c71\u...",
 *       "identifier": "913206007431271330",
 *       "suspicious": true,
 *       "latitude": 50,
 *       "longitude": 115,
 *       "full_address": "140, Xyz Rd, ...",
 *       "transactions_involved": 105,
 *       "company_name_legal": "\u534e\u5b5a\u65f...",
 *       "source": "investigation",
 *       "investigation_status_id": 2,
 *       "country_id": 46,
 *       "geocode_level": "100",
 *       "visible": true
 *     }
 * 
 */
export const getInfo = async (companyId) => {
    const response = await axiosRequest.get(`${API_BASE}/getinfo/?id=${companyId}`);
    return response;
}

/**
 * Fetches evidences related to a specific company based on search criteria.
 * 
 * @param {number} company - The ID of the company to retrieve evidences for.
 * 
 * @returns {Object} response - The response object.
 * @returns {Evidence[]} response.evidences - A list of Evidence objects.
 * @returns {boolean} response.source_investigation - The source investigation status.
 * 
 * @example
 * // Response example:
 * {
 *   "evidences": [
 *     {
 *       "transaction_date": "2022-04-23",
 *       "media_reported_date": "2022-04-23",
 *       "source": "<p>This company has an investment in XYZ...</p>",
 *       "information_source": "Investigation"
 *     },
 *     {
 *       "transaction_date": "2018-11-30",
 *       "media_reported_date": "2018-11-30",
 *       "source": "<p>ABC subsidiaries provided training to XYZ...</p>",
 *       "information_source": "Investigation"
 *     }
 *   ],
 *   "source_investigation": false
 * }
 */
export const getEvidences = async (companyId) => {
    const response = await axiosRequest.get(`${API_BASE}/getEvidences/?search=${companyId}`);
    return response;
}


/**
 * Fetches supplier data for a specific company and product.
 *
 * @param {Object} filterQuery - The filterQuery object containing query parameters.
 * @param {string} filterQuery.company - The company ID.
 * @param {string} filterQuery.product - The product identifier (e.g., "ALL").
 * @param {number} filterQuery.tiers - The tier level for the supplier data.
 * 
 * @returns {Object} response - The response object.
 * @returns {Object} response.sus_relations - A map of suspicious relations.
 * @returns {Object[]} response.tiers - A list of tiered supplier data.
 * @returns {Company[]} response.tiers.shippers - A list of Company objects representing shippers.
 * @returns {Relation[]} response.tiers.from_relations - A list of Relation objects.
 * @returns {Company} response.company - The company details for the given company ID.
 * @returns {number} response.startY - The start year for the data.
 * @returns {number} response.endY - The end year for the data.
 * @returns {boolean} response.has_suppliers - Whether the company has suppliers.
 * 
 * @example
 * //Example Response:
 * {
 *   "sus_relations": {
 *     "0": [63552291, 3293869, 3293871, 3293872, 3293873, 3293874, 3293875, 3293876, 2163033],
 *     "1": [63552291, 3293869, 3293871, 3293872, 3293873, 3293874, 3293875, 3293876, 2163033]
 *   },
 *   "tiers": [
 *     {
 *       "shippers": [
 *         {
 *           "id": 3293875,
 *           "company_name_english": "Jiangsu Haoye Yaochen Textile Industry Co., Ltd.",
 *           "suspicious": true,
 *           "latitude": null,
 *           "longitude": null,
 *           "full_address": "",
 *           "transactions_involved": 1,
 *           "company_name_legal": null,
 *           "source": "investigation",
 *           "geocode_level": "",
 *           "visible": true,
 *           "investigation_status": null,
 *           "country": 46
 *         }
 *       ],
 *       "from_relations": [
 *         {
 *           "id": 2803633,
 *           "from_company": {
 *             "id": 3293874,
 *             "company_name_english": "Jiangyin Dongyuan Garment Co., Ltd.",
 *             "full_address": "",
 *             "suspicious": true,
 *             "country": { "name": "China" }
 *           },
 *           "to_company": {
 *             "id": 2163033,
 *             "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *             "full_address": "Floor 23-F, Guotai Times Plaza, Building A, Renmin Middle Road...",
 *             "suspicious": true,
 *             "country": { "name": "China" }
 *           },
 *           "total_transactions": "1"
 *         }
 *       ]
 *     }
 *   ],
 *   "company_name": "Jiangsu Guotai International Group Co., Ltd.",
 *   "company": {
 *     "id": 2163033,
 *     "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *     "suspicious": true,
 *     "latitude": 31.929111,
 *     "longitude": 120.703348,
 *     "full_address": "Floor 23-F, Guotai Times Plaza...",
 *     "transactions_involved": 809,
 *     "source": "Importyeti",
 *     "country": 46
 *   },
 *   "startY": 2019,
 *   "endY": 2021,
 *   "has_suppliers": true
 * }
 */
export const getSupplierData = async (filterQuery) => {
    const response = await axiosRequest.get(`${API_BASE}/loadSupplierInfo/`, {
        params: filterQuery,
    });
    return response;
};

/**
 * Fetches customer data for a specific company and product.
 *
 * @param {Object} filterQuery - The filterQuery object containing query parameters.
 * @param {string} filterQuery.company - The company ID.
 * @param {string} filterQuery.product - The product identifier (e.g., "ALL").
 * @param {number} filterQuery.tiers - The tier level for the customer data.
 * 
 * @returns {Object} response - The response object.
 * @returns {Object} response.sus_relations - A map of suspicious relations.
 * @returns {Object[]} response.tiers - A list of tiered customer data.
 * @returns {Company[]} response.tiers.consignees - A list of Company objects representing consignees.
 * @returns {Relation[]} response.tiers.to_relations - A list of Relation objects.
 * @returns {Company} response.company - The company details for the given company ID.
 * @returns {number} response.startY - The start year for the data.
 * @returns {number} response.endY - The end year for the data.
 * @returns {boolean} response.has_customers - Whether the company has customers.
 * 
 * @example
 * // Example Response:
 * {
 *   "sus_relations": {
 *     "0": [63552291, 3293869, 3293871, 3293872, 3293873, 3293874, 3293875, 3293876, 2163033],
 *     "1": [63552291, 3293869, 3293871, 3293872, 3293873, 3293874, 3293875, 3293876, 2163033]
 *   },
 *   "tiers": [
 *     {
 *       "consignees": [
 *         {
 *           "id": 3293875,
 *           "company_name_english": "Jiangsu Haoye Yaochen Textile Industry Co., Ltd.",
 *           "suspicious": true,
 *           "latitude": null,
 *           "longitude": null,
 *           "full_address": "",
 *           "transactions_involved": 1,
 *           "company_name_legal": null,
 *           "source": "investigation",
 *           "geocode_level": "",
 *           "visible": true,
 *           "investigation_status": null,
 *           "country": 46
 *         }
 *       ],
 *       "to_relations": [
 *         {
 *           "id": 2803633,
 *           "from_company": {
 *             "id": 3293874,
 *             "company_name_english": "Jiangyin Dongyuan Garment Co., Ltd.",
 *             "full_address": "",
 *             "suspicious": true,
 *             "country": { "name": "China" }
 *           },
 *           "to_company": {
 *             "id": 2163033,
 *             "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *             "full_address": "Floor 23-F, Guotai Times Plaza, Building A, Renmin Middle Road...",
 *             "suspicious": true,
 *             "country": { "name": "China" }
 *           },
 *           "total_transactions": "1"
 *         }
 *       ]
 *     }
 *   ],
 *   "company_name": "Jiangsu Guotai International Group Co., Ltd.",
 *   "company": {
 *     "id": 2163033,
 *     "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *     "suspicious": true,
 *     "latitude": 31.929111,
 *     "longitude": 120.703348,
 *     "full_address": "Floor 23-F, Guotai Times Plaza...",
 *     "transactions_involved": 809,
 *     "source": "Importyeti",
 *     "country": 46
 *   },
 *   "startY": 2019,
 *   "endY": 2021,
 *   "has_customers": true
 * }
 */
export const getCustomerData = async (filterQuery) => {
    const response = await axiosRequest.get(`${API_BASE}/loadCustomerInfo/`, {
        params: filterQuery,
    });
    return response;
};

/**
 * Fetches the locations of a specific company.
 *
 * @param {Object} companyId - The company ID.
 * 
 * @returns {Object} response - The response object.
 * @returns {Company} response.company - The company details for the given company ID.
 * @returns {Object[]} response.locations - A list of location objects associated with the company.
 * @returns {number} response.locations.id - The location ID.
 * @returns {string} response.locations.company_name_english - The English company name at the location.
 * @returns {boolean} response.locations.suspicious - Whether the location is suspicious.
 * @returns {string} response.locations.full_address - The full address of the location.
 * @returns {number} response.locations.latitude - The latitude of the location.
 * @returns {number} response.locations.longitude - The longitude of the location.
 * 
 * @example
 * // Example Response:
 * {
 *   "company": {
 *     "id": 2163033,
 *     "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *     "brand": null,
 *     "identifier": "91320000703675629U",
 *     "suspicious": true,
 *     "latitude": 31.929111,
 *     "longitude": 120.703348,
 *     "full_address": "Floor 23-F, Guotai Times Plaza...",
 *     "transactions_involved": 809,
 *     "company_name_legal": "Jiangsu Guotai International Group...",
 *     "source": "Importyeti",
 *     "geocode_level": "100",
 *     "visible": true,
 *     "investigation_status": 2,
 *     "country": 46
 *   },
 *   "locations": [
 *     {
 *       "id": 59110866,
 *       "company_name_english": "Jiangsu Guotai Guosheng (Jiangsu Guotai International Group)",
 *       "suspicious": true,
 *       "full_address": "Floor 18-F, Guotai Times Plaza...",
 *       "latitude": 31.929111,
 *       "longitude": 120.703348
 *     },
 *     {
 *       "id": 59111672,
 *       "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *       "suspicious": true,
 *       "full_address": "Floor 23-F, Guotai Times Plaza...",
 *       "latitude": 31.929111,
 *       "longitude": 120.703348
 *     },
 *     {
 *       "id": 59111673,
 *       "company_name_english": "Jiangsu Guotai International Group Co., Ltd.",
 *       "suspicious": true,
 *       "full_address": "Floor 23-F, Guotai Times Plaza...",
 *       "latitude": 31.929111,
 *       "longitude": 120.703348
 *     }
 *   ]
 * }
 */
export const getCompanyLocations = async (companyId) => {
    const response = await axiosRequest.get(`${API_BASE}/loadCompanyLocations/?company=${companyId}`);
    return response;
}

/**
 * Fetches supplier transaction data based on specified filters.
 *
 * @param {Object} filterQuery - The filter query parameters for retrieving supplier transactions.
 * @param {string} filterQuery.company - The company ID to filter transactions.
 * @param {string} filterQuery.product - The product identifier, such as "ALL".
 * @param {number} filterQuery.tiers - The number of tier levels to include in the data.
 * 
 * @returns {Object} response - The response object containing the supplier transaction data.
 * @returns {Object} response.sus_relations - A map of suspicious relations by tier.
 * @returns {Tier[]} response.tiers - An array of tiered transaction data.
 * @returns {string} response.company_name - The name of the company associated with the supplier transactions.
 * @returns {Company} response.company - The details of the specified company.
 * @returns {number} response.startY - The start year for the transaction data.
 * @returns {number} response.endY - The end year for the transaction data.
 * @returns {boolean} response.has_suppliers - Indicates if the company has suppliers.
 * 
 * @example
 * // Example response:
 * {
 *   "sus_relations": { "0": [] },
 *   "tiers": [
 *     {
 *       "shippers": [
 *         {
 *           "id": 3293846,
 *           "company_name_english": "XYZ Textile Co., Ltd.",
 *           "suspicious": true,
 *           // other Company properties
 *         }
 *       ],
 *       "consignees": [],
 *       "from_relations": [
 *         {
 *           "id": 2804220,
 *           "from_company__id": 2163033,
 *           "to_company__id": 2135875,
 *           // other Relation properties
 *         }
 *       ],
 *       "to_relations": [],
 *       "tier": 1
 *     }
 *   ],
 *   "company_name": "Jiangsu Guotai Guosheng (Jiangsu Guotai International Group)",
 *   "company": {
 *     "id": 59110866,
 *     "company_name_english": "Jiangsu Guotai Guosheng (Jiangsu Guotai International Group)",
 *     "full_address": "Floor 18-F, Guotai Times Plaza, Building A, Renmin Middle Road...",
 *     "latitude": 31.929111,
 *     "longitude": 120.703348,
 *     "suspicious": true,
 *     "country": 46
 *   },
 *   "startY": 2022,
 *   "endY": 2024,
 *   "has_suppliers": false
 * }
 */
export const getSupplierTransactions = async (filterQuery) => {
    const response = await axiosRequest.get(`${API_BASE}/loadSupplierTransactions/`, {
        params: filterQuery,
    });
    return response;
};

/**
 * Fetches customer transaction data based on specified filters.
 *
 * @param {Object} filterQuery - The filter query parameters for retrieving customer transactions.
 * @param {string} filterQuery.company - The company ID to filter transactions.
 * @param {string} filterQuery.product - The product identifier, such as "ALL".
 * @param {number} filterQuery.tiers - The number of tier levels to include in the data.
 * 
 * @returns {Object} response - The response object containing the customer transaction data.
 * @returns {Object} response.sus_relations - A map of suspicious relations by tier.
 * @returns {Tier[]} response.tiers - An array of tiered transaction data.
 * @returns {string} response.company_name - The name of the company associated with the customer transactions.
 * @returns {Company} response.company - The details of the specified company.
 * @returns {number} response.startY - The start year for the transaction data.
 * @returns {number} response.endY - The end year for the transaction data.
 * @returns {boolean} response.has_customers - Indicates if the company has customers.
 * 
 * @example
 * // Example response:
 * {
 *   "sus_relations": { "0": [] },
 *   "tiers": [
 *     {
 *       "shippers": [],
 *       "consignees": [
 *         {
 *           "id": 59110866,
 *           "company_name_english": "ABC Corporation",
 *           "suspicious": false,
 *           // other Company properties
 *         }
 *       ],
 *       "from_relations": [],
 *       "to_relations": [
 *         {
 *           "id": 2804221,
 *           "from_company__id": 2135875,
 *           "to_company__id": 2163033,
 *           // other Relation properties
 *         }
 *       ],
 *       "tier": 1
 *     }
 *   ],
 *   "company_name": "Jiangsu Guotai Guosheng (Jiangsu Guotai International Group)",
 *   "company": {
 *     "id": 59110866,
 *     "company_name_english": "Jiangsu Guotai Guosheng (Jiangsu Guotai International Group)",
 *     "full_address": "Floor 18-F, Guotai Times Plaza, Building A, Renmin Middle Road...",
 *     "latitude": 31.929111,
 *     "longitude": 120.703348,
 *     "suspicious": true,
 *     "country": 46
 *   },
 *   "startY": 2022,
 *   "endY": 2024,
 *   "has_customers": true
 * }
 */
export const getCustomerTransactions = async (filterQuery) => {
    const response = await axiosRequest.get(`${API_BASE}/loadCustomerTransactions/`, {
        params: filterQuery,
    });
    return response;
};