import React, { useState } from "react";
import { Group } from "@vx/group";
import { Text } from "@vx/text";
import Sankey from "./CircularSankey";

const SankeyChart = ({ data, width, height, margin = { top: 0, left: 0, right: 100, bottom: 0 } }) => {
  const [svgWidth, setSvgWidth] = useState(width + margin.left + margin.right);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const [connectedLinks, setConnectedLinks] = useState([]);

  const handleNodeMouseOver = (node) => {
    setHoveredNode(node);

    // Find incoming and outgoing links connected to the hovered node
    const incomingLinks = data.links.filter(link => link.target === node);
    const outgoingLinks = data.links.filter(link => link.source === node);
    const links = [...incomingLinks, ...outgoingLinks];

    setConnectedLinks(links);
    setHoveredLink(null); // Reset hovered link when hovering over a node
  };

  const handleNodeMouseOut = () => {
    setHoveredNode(null);
    setConnectedLinks([]);
    setHoveredLink(null);
  };

  const handleLinkMouseOver = (link,event) => {
    setHoveredLink(link);
    setHoveredNode(null); // Reset hovered node when hovering over a link
    const { clientX, clientY } = event;
    setPopupPosition({ x: clientX/2+10, y: clientY/2 });
  };

  const handleLinkMouseOut = () => {
    setHoveredLink(null);
    setHoveredNode(null);
    setPopupPosition({ x: 0, y: 0 });

  };

  if (width < 10) return null;

  return (
    <svg width={svgWidth} height={height}>
      <Sankey
        top={margin.top}
        left={margin.left}
        data={data}
        size={[width, height]}
        nodeWidth={15}
        nodePadding={40}
        nodePaddingRatio={0.1}
        nodeId={(d) => d.name}
        iterations={32}
      >
        {({ data }) => (
          <Group>
            {data.nodes.map((node, i) => (
              <Group
                top={node.y0}
                left={node.x0}
                key={`node-${i}`}
                onMouseOver={() => handleNodeMouseOver(node)}
                onMouseOut={handleNodeMouseOut}
              >
                <rect
                  id={`rect-${i}`}
                  width={node.x1 - node.x0}
                  height={node.y1 - node.y0}
                  fill={node.color}
                  opacity={hoveredNode === node ? 1 : 0.8}
                />

                <Text
                  x={18}
                  y={(node.y1 - node.y0) / 2}
                  verticalAnchor="middle"
                  style={{
                    font: "10px sans-serif",
                  }}
                >
                  {node.category}
                </Text>
              </Group>
            ))}

            <Group strokeOpacity={0.2}>
              {data.links.map((link, i) => (
                <path
                  key={`link-${i}`}
                  d={link.path}
                  stroke={
                    hoveredLink === link ? "blue" : hoveredNode && connectedLinks.includes(link) ? "blue" : "steelblue"
                    }
                    strokeWidth={Math.max(10, link.width - 10)}

                  opacity={
                    1
                  }
                  fill="none"
                  onMouseOver={(event) => handleLinkMouseOver(link, event)}
                  onMouseOut={handleLinkMouseOut}
                />
              ))}
            </Group>
          </Group>
        )}
      </Sankey>

      {hoveredLink && (
        <g transform={`translate(${popupPosition.x} , ${popupPosition.y})`}>
          <rect
            width={250}
            height={80}
            fill="white"
            stroke="steelblue"
            strokeWidth={2}
            rx={8}
            ry={8}
          />
          <Text x={10} y={20} style={{ font: "12px sans-serif" }}>
            {`From : ${hoveredLink.source.category}`}
          </Text>
          <Text x={10} y={40} style={{ font: "12px sans-serif" }}>
            {`To : ${hoveredLink.target.category}`}
          </Text>
          <Text x={10} y={60} style={{ font: "12px sans-serif" }}>
            {`Transactions : ${hoveredLink.total_transactions}`}
          </Text>
        </g>
      )}
    </svg>
  );
};

export default SankeyChart;
