import React from 'react';
import { Box, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TableChartIcon from '@mui/icons-material/TableChart';

const ThreeOptionToggle = ({ selected, setSelected }) => {
  const containerStyle = {
    display: 'flex',
    borderRadius: '9999px',
    border: '2px solid black',
    width: 'fit-content',
  };

  const optionStyle = (isActive, isLeft, isRight) => ({
    backgroundColor: isActive ? 'black' : 'transparent',
    color: isActive ? 'white' : 'black',
    borderRadius: isLeft 
      ? '9999px 0 0 9999px' 
      : isRight 
        ? '0 9999px 9999px 0' 
        : '0',
    padding: '8px 16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    transition: 'all 0.3s ease',
  });

  const handleToggle = (option) => {
    setSelected(option);
  };

  const options = [
    { key: 'map', label: 'Map', icon: <MapIcon />, isLeft: true },
    { key: 'sankey', label: 'Sankey', icon: <AccountTreeIcon />, isMiddle: true },
    { key: 'table', label: 'Table', icon: <TableChartIcon />, isRight: true },
  ];

  return (
    <Box sx={containerStyle}>
      {options.map((option) => (
        <Box
          key={option.key}
          onClick={() => handleToggle(option.key)}
          sx={optionStyle(
            selected === option.key,
            option.isLeft,
            option.isRight
          )}
        >
          {option.icon}
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {option.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ThreeOptionToggle;