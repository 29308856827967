import React, { useState, Fragment, useEffect, useCallback } from 'react'
import './index.css'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField } from '@mui/material';

import YearFilter from '../../YearFilter';
import CircularProgress from '@mui/material/CircularProgress';

import { axiosRequest } from '../../../services/utils/axios';

import axios from 'axios';
import { API_BASE } from '../../../constants';

import Chip from '@mui/material/Chip';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import { debounce } from "lodash";

const CancelToken = axios.CancelToken;

const RelationEntryFilterComponent = ({ supplierFilters, setSupplierFilters, handleFilterSelection }) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 2;
        
    const defaultFromYear = previousYear;
    const defaultToYear = currentYear;

    const [filterStatus, setFilterStatus] = useState("draft") // draft or applied

    const [expandFilter, setExpandFilter] = useState(true);
    

    // const [supplierFilters, setSupplierFilters] = useState({
    //     draft: {
    //         tier: "3", year: { from: defaultFromYear, to: defaultToYear }
    //     },
    //     applied: {
    //         tier: "3", year: { from: defaultFromYear, to: defaultToYear }
    //     },
    // })


    const handleYearDeletion = () => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                year: {
                    from: defaultFromYear,
                    to: defaultToYear
                }
            }
        }
        setSupplierFilters(filters)
    }

    const handleYearChange = (fromYear, toYear) => {
        const filters = {
            ...supplierFilters,
            [filterStatus]: {
                ...supplierFilters[filterStatus],
                year: {
                    from: fromYear,
                    to: toYear
                }
            }
        }
        setSupplierFilters(filters)
    }

    const handleFilterCancel = () => {
        setExpandFilter(false)
        setFilterStatus("draft")
        const filters = { ...supplierFilters, draft: { ...supplierFilters["applied"] } }
        setSupplierFilters(filters)


    }

    const handleApplyFilter = () => {
        // TODO call API to apply filter
        setExpandFilter(false)
        const filters = { ...supplierFilters, applied: { ...supplierFilters["draft"] } }
        setSupplierFilters(filters)
        setFilterStatus("applied")
        handleFilterSelection(supplierFilters["draft"])
    }
    console.log(filterStatus, supplierFilters, supplierFilters[filterStatus],"popop s")
    return (
        <div className={`${expandFilter ? 'supplier-filter-main-expanded-container' : 'supplier-filter-main-container'}`}>
            <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                endIcon={expandFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                color="info"
                className='supplier-filters-btn'
                aria-label='Filter options open'
                onClick={() => { setFilterStatus('draft');console.log("cli"); setExpandFilter(!expandFilter)}}
            >
                Relationships in Years
            </Button>
            <div className={`${expandFilter ? 'supplier-filter-secondary-expanded-container' : 'supplier-filter-secondary-container'}`}>
                {
                    expandFilter && <div className='supplier-filter-container'>
                        <div className='supplier-filter-header'>
                        </div>

                        {/* <div className='supplier-countries-filter'>
                            <Autocomplete
                                multiple
                                disablePortal
                                id="supplier-countries-filter"
                                options={countries}
                                sx={{ width: '100%' }}
                                onChange={handleCountryChange}
                                value={supplierFilters[filterStatus].countries}
                                renderTags={() => null}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Countries"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            borderRadius: "0px",
                                        }
                                    }}

                                />
                                }
                            />
                        </div> */}

       
                        <div className='year-drop-down-container'>
                            <YearFilter defaultFromYear={supplierFilters['applied']['year']['from']} defaultToYear={supplierFilters['applied']['year']['to']} handleYearsSelection={handleYearChange}></YearFilter>
                        </div>  
                    </div>
                }
                <div className='selected-suppliers-container'>
                    
                    {
                        supplierFilters[filterStatus]?.year.from && supplierFilters[filterStatus]?.year.to && <div className='selected-supplier-container'>
                            <Chip
                                color="primary"
                                label={supplierFilters[filterStatus]?.year.from === supplierFilters[filterStatus]?.year.to ? `Year | ${supplierFilters[filterStatus]?.year.from}` : `Year | ${supplierFilters[filterStatus]?.year.from} to ${supplierFilters[filterStatus]?.year.to}`}
                                {...expandFilter ? { onDelete: handleYearDeletion, variant: "outlined" } : {variant: "outlined"}}
                                variant="filled"
                            />
                        </div>
                    }
                </div>

                {expandFilter && <div className='supplier-apply-filters-btn-container'>
                    <Button
                        className='clear-supplier-filters-btn'
                        aria-label="cancel button"
                        variant="outlined"
                        onClick={handleFilterCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='supplier-apply-filters-btn'
                        aria-label="apply filter"
                        variant="contained"
                        onClick={handleApplyFilter}
                    >
                        Apply Filters
                    </Button>
                </div>}
            </div>
        </div>
    )
}

export default RelationEntryFilterComponent;