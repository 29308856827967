import React, {useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { Grid, TextField, Button } from "@mui/material";
import { OutlinedInput } from '@mui/material';
import { FormControl, MenuItem, Select ,Checkbox, FormControlLabel} from '@mui/material';

import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";
import { sendOTP, register } from "../../services/login-service";
import { setUserVerified, setCredentials } from "../Reducers/auth-reducer";

import "./login.css";

const organizationTypes = [
  "Academic / Researcher / Journalist / Student",
  "Auditor / Certification Scheme / Service Provider",
  "Brand / Retailer / Company",
  "Civil Society Organization",
  "Government",
  "Law Enforcement",
  "Multi-Stakeholder Initiative",
  "Trade / Compliance",
  "Other"
];

const Register = () => {

  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginInput, setLoginInput] = useState({});
  const [passwordMatch, setPasswordMatch] = useState(true); 
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [captchaValue, setCaptcha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [organizationType, setOrganizationType] = useState('Academic / Researcher / Journalist / Student');
  const [otherOrganization, setOtherOrganization] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [registerEnabled, setRegisterEnabled] = useState(false);

  useEffect(()=> {
    checkRegisterEnable() ? setRegisterEnabled(true) : setRegisterEnabled(false);
  },[loginInput, isCaptchaValidated, isTermsAccepted, passwordMatch, organizationType, otherOrganization]);

  const checkRegisterEnable = () => {
    if (isCaptchaValidated && isTermsAccepted &&
      loginInput.username && loginInput.username !== '' &&
      loginInput.password && loginInput.password !== '' &&
      loginInput['retype password'] && loginInput['retype password'] !== '' &&
      (organizationType !== 'Other' || otherOrganization !== '')) {
      return true;
    }
    return false;
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setLoginInput(prevState => ({
      ...prevState,
      [name]: newValue,
    }));
    setErrorMessage('');
    setPasswordMatch(true);
  };

  const validateInputs = () => {
    if (loginInput.password !== loginInput['retype password']) {
      setPasswordMatch(false);
      return false;
    }
    return true;
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value)
    setIsCaptchaValidated(true);
  }

  const registerUser = async () => {
    try {
      const registrationData = {
        username: loginInput['username'],
        email: loginInput['email'],
        password: loginInput['password'],
        organisation_type: organizationType!=='Other' ? organizationType : otherOrganization,
        'g-recaptcha-response': captchaValue,
        'accept_tandc': isTermsAccepted
      };
      const response = await register(registrationData);
      dispatch(setCredentials({
        username: loginInput['username'],
        password: loginInput['password']
      }));
      setVerify(loginInput['username']);
      console.log(response.data);
    } catch (error) {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      setErrorMessage(error.response.data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting registration form...');
    if (validateInputs()) {
      try {
        setLoginLoading(true);
        await registerUser();
      } catch (error) {
      } finally {
        setLoginLoading(false);
      }
    }
  };

  const handleOrganizationTypeChange = (event) => {
    setOrganizationType(event.target.value);
  };

  const handleTermsCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleOtherOrganizationChange = (event) => {
    setOtherOrganization(event.target.value);
  };

  const setVerify = async (email) => {
    try {
      await sendOTP(email);
      alert("OTP Sent");
      const encodedEmail = encodeURIComponent(email);
      dispatch(setUserVerified(true));
      navigate(`/user-verification/${encodedEmail}`);
    } catch (error) {
      setErrorMessage(error.response.data);
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>

          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="register_username" style={{margin: 0}}>Email</label>
            <TextField
              id="register_username"
              fullWidth
              required
              aria-label="username text box"
              name="username"
              type="email"
              onChange={handleInput}
              inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$" }}
            />
          </Grid>

          <Grid item xs={6} className="wd-login-item">
            <div>
              <FormControl fullWidth>
                <label htmlFor="organization-type-label" style={{margin: 0}}>Organization Type</label>
                <Select
                  id="organization-type"
                  value={organizationType}
                  input={<OutlinedInput notched />}
                  onChange={handleOrganizationTypeChange}
                  fullWidth
                >
                  {organizationTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>

          {organizationType === 'Other' && (
            <Grid item xs={6} className="wd-login-item">
              <label htmlFor="other-organization" style={{margin: 0}}>Other organization type</label>
              <TextField
                id="other-organization"
                value={otherOrganization}
                onChange={handleOtherOrganizationChange}
                fullWidth
                required
                // helperText="Please specify"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          )}

          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="register_password" style={{ margin: 0 }}>Password</label>
            <TextField
              id="register_password"
              required
              fullWidth
              aria-label="password text box"
              name="password"
              type="password"
              onChange={handleInput}
            />
          </Grid>

          <p style={{ margin: '0 1.5rem', fontSize: '13px' }}>
            Password should be at least 8 characters long, include one capital letter, and one special character.
          </p>

          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="retyped_password" style={{ margin: 0 }}>Re-type password</label>
            <TextField
              id="retyped_password"
              required
              fullWidth
              aria-label="password text box"
              name="retype password"
              type="password"
              onChange={handleInput}
              error={!passwordMatch}
            />
          </Grid>

          <div
            style={{
              display: passwordMatch ? 'none' : 'block',
              color: 'red',
              marginTop: '8px',
            }}>
            Passwords do not match
          </div>

          <Grid item xs={12} className="wd-login-item">
            {
             <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
            }
          </Grid>

          <Grid item xs={12} className="wd-login-item" style={{ margin: '10px 0' }}>
            <FormControlLabel
              control={<Checkbox checked={isTermsAccepted} onChange={handleTermsCheckboxChange} />}
              label={
                <span style={{ fontSize: '1.1rem' }}>
                  I accept the <a href="https://info.supplytrace.org/terms-of-use/ " target="_blank" rel="noopener noreferrer">Terms of Use</a>
                </span>
              }
            />
          </Grid>

          {Object.keys(errorMessage).length > 0 && (
            <div style={{ color: 'red', marginBottom: '1rem' }}>
              {Object.keys(errorMessage).map((field, index) => (
                <div key={index}>
                  {field}: {errorMessage[field].join(', ')}
                </div>
              ))}
            </div>
          )}

          <Grid item xs={12} className="login-full-width">
            <Button
              className="wd-submit-btn-login"
              type="submit"
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
              disabled={!registerEnabled}
            >
              Create Account
            </Button>
          </Grid>

        </Grid>
      </form>
  );
};

export default Register;
