import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';
import { MDBBtn } from "mdb-react-ui-kit";

import Signin from './Signin';
import Register from './Register';

import "./login.css";

const LoginPage = ({ setIsAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/login') setTabValue(0);
    else if (pathname === '/register') setTabValue(1);
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) setSignIn();
    else if (newValue === 1) setRegister();
  };

  const setRegister = (event) => {
    navigate('/register')
  };

  const setSignIn = (event) => {
    navigate('/login')
  };

  return (
    <div className="login-window">
      
      <Link to="/" className="unstyled-link">
        <div className="login-header">
          <img
            className="login-page-logo"
            src={require('./logo/ST_OneLine_RGB.png')}
            alt="our logo"
          />
          <h3 className="login-page-logo-tagline">Open supply chain intelligence for a just world</h3>
        </div>
      </Link>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="login register tabs"
        style={{ marginBottom: '30px' }}>
        <Tab label="Sign in" />
        <Tab label="Create Account" />
      </Tabs>

      {tabValue === 0 && <Signin setIsAuthenticated={setIsAuthenticated} />}
      {tabValue === 1 && <Register />}

      <div className="d-flex flex-row align-items-center justify-content-left pb-4 mt-4">
        <p className="mb-0 mr-2" style={{ fontWeight: '600' }}>
          {tabValue === 0 ? "Don't have an account?" : "Already have an account?"}
        </p>
        <MDBBtn outline color="danger" onClick={() => tabValue === 0 ? setRegister() : setSignIn()}>
          {tabValue === 0 ? "Create Account" : "Sign in"}
        </MDBBtn>
      </div>

    </div>
  );
};

export default LoginPage;