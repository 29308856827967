import React, { useState, useMemo } from 'react';
import TopBarChartComponent from './BarChartComponent';

const TopSupplierCustomerChart = ({ company_info }) => {
  const [selectedOption, setSelectedOption] = useState('Suppliers');

  const filteredData = useMemo(() => {
    if(selectedOption === 'Suppliers') {
        return company_info?.top_suppliers
    } else {
        return company_info?.top_customers
    }
  }, [company_info, selectedOption]);

  return (
    <div className="w-full max-w-3xl border rounded-lg shadow-sm">
      <div className="flex flex-row items-center justify-between p-4 border-b">
        <h2 className="text-lg font-medium flex items-center">
        <span
            style={{color: '#0f73be',fontWeight: 'bold'}}          >
            Top 
          </span> 
          <select 
            value={selectedOption} 
            onChange={(e) => setSelectedOption(e.target.value)}
            className="ml-2 p-1 border rounded text-primary-600 font-medium"
            style={{color: '#0f73be', backgroundColor: '#dddddd', fontWeight: 'bold'}}
          >
            <option value="Suppliers">Suppliers</option>
            <option value="Customers">Customers</option>
          </select>
        </h2>
      </div>
      <div className="p-4">
        <TopBarChartComponent is_supplier={selectedOption === 'Suppliers'} data={filteredData} />
      </div>
    </div>
  );
};

export default TopSupplierCustomerChart;