import React from 'react';
import './index.css';
import { DASHBOARD_SUMMARY_TEMPLATE, SUMMARY_NOTE } from '../../../constants';
import { Tooltip, IconButton } from '@mui/material';
import { InfoIcon } from 'lucide-react';

const DashboardSummaryComponent = ({ shipmentCount, locCount, firstYear, endYear }) => {
  const template = DASHBOARD_SUMMARY_TEMPLATE;

  const handleLinkClick = (e, hash) => {
    e.preventDefault();
    window.location.hash = hash;
  };

  // example summary: 16,198 shipments traced to or from 3 locations from 2014 to 2024
  const summaryText = template
    .replace('[#]', shipmentCount)
    .replace('[#loc]', locCount)
    .replace('years [first_year]', firstYear === endYear ? `year ${firstYear}` : `years ${firstYear}`)
    .replace('to [end_year]', firstYear === endYear ? "" : `to ${endYear}`);

  // Define clickable terms and their corresponding hashes
  const clickableTerms = {
    locations: 'locations',
    "relationships": 'relationships'
  };

  // Function to render parts with clickable terms as links
  const renderTextWithLinks = (text) => {
    const parts = text.split(new RegExp(`(${Object.keys(clickableTerms).join('|')})`, 'gi'));
    return parts.map((part, index) => {
      const lowercasePart = part.toLowerCase();
      if (clickableTerms.hasOwnProperty(lowercasePart)) {
        return (
          <a
            key={index}
            href={`#${clickableTerms[lowercasePart]}`}
            onClick={(e) => handleLinkClick(e, clickableTerms[lowercasePart])}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className='summary-container'>
      <Tooltip title={SUMMARY_NOTE} placement="top" arrow className='ml-disclaimer-tooltip'>
        <IconButton size="large">
          <InfoIcon color="black" />
        </IconButton>
      </Tooltip>
      <p>{renderTextWithLinks(summaryText)}</p>
    </div>
  );
};

export default DashboardSummaryComponent;