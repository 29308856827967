import React from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './index.css'

const Footer = () => {

    return (
        <div className="footer-container">

            <div className="footer-container-left">
                <div className="footer-image-conatiner">
                    <img width={100} src="/logo/ST_Landscape_RGB.png">
                    </img>
                </div>
                <div className="footer-content-1">
                    <div className="footer-content-1-item">
                        <a href="https://info.supplytrace.org/faqs/" target="_blank" rel="noopener noreferrer" className="footer-link font-bold">
                            FAQ
                        </a>                    
                    </div>
                    <div className="footer-content-1-item">
                        <a href="https://info.supplytrace.org/media-hub/" target="_blank" rel="noopener noreferrer" className="footer-link font-bold">
                            Media Hub
                        </a>
                    </div>

                    <div className="footer-content-1-item">
                        <a href="https://info.supplytrace.org/contact-us/" target="_blank" rel="noopener noreferrer" className="footer-link font-bold">
                            Contact
                        </a>
                    </div>
                </div>
                <div className="foot-content-2-left">
                    <div className="footer-content-1-item">
                        <a href="https://info.supplytrace.org/terms-of-use/" target="_blank" rel="noopener noreferrer" className="footer-bottom-link">
                            Terms of Service
                        </a>
                    </div>
                    <div className="footer-content-1-item">
                        <a href="https://info.supplytrace.org/privacy-policy/" target="_blank" rel="noopener noreferrer" className="footer-bottom-link">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-container-right">
                <div className="footer-container-newsletter">
                    <div>
                        <div className="footer-newsletter-header">
                            Newsletter Sign-Up
                        </div>
                        <div className="newsletter-underline"></div>
                        <div className="footer-newsletter-form">
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    InputProps={{ sx: { borderRadius: 0 } }} style={{ color: "#2085c2" }} />
                            </div>
                            <Button variant="outlined" style={{ borderRadius: 0 }}>Subscribe</Button>
                        </div>
                    </div>
                </div>
                <div className="footer-content-1-item footer-bottom-link ">
                    Copyright © 2024 Supply Trace. All Rights Reserved.
                </div>
            </div>

        </div>
    )

}

export default Footer;