import { useState,useEffect, useRef } from "react"
import { axiosRequest } from "../../../../services/utils/axios";
import { API_BASE } from "../../../../constants";
import './index.css';
import L from "leaflet";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import Button from "@mui/material/Button";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: red[600],
      '&:hover': {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: red[600],
    },
  }));
const CompanyLocationsMap = ({ companyLocations, companyDetails }) => {
    const [displayDetails, setDisplayDetails] = useState(companyLocations);
    const filterLocs = (companyLocations) => {
         return companyLocations.filter((loc) => loc?.suspicious === true);
    }
    const locMapRef = useRef(null);
    const markersRef = useRef(null);
    const [suspiciousOnly, setSuspiciousOnly] = useState(false)
    const setSus = () => {
        setSuspiciousOnly((prevSuspiciousOnly) => {      
          return !prevSuspiciousOnly;
        });

    };
    useEffect(() => {
        if(suspiciousOnly){
            setDisplayDetails(filterLocs(companyLocations))
        } else {
            setDisplayDetails(companyLocations)
        }
    },[suspiciousOnly, companyDetails])
    const handleZoomOut = () => {
        if (locMapRef.current) {
          // Check if the map instance exists
          locMapRef.current.setView([0, 0], 2.4); // Set the view to the initial position
        }
    };
    useEffect(() => {
        
        let locMap = locMapRef.current;
        if (!locMap) {
            locMap = L.map('location-map-id').setView(
                [companyDetails?.latitude || 0, companyDetails?.longitude || 0],
                12
            );

            L.tileLayer(
                "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
                {
                    attribution:
                        'Map tiles by <a href="https://carto.com/">Carto</a>, ' +
                        'data by <a href="https://openstreetmap.org/">OpenStreetMap</a>',
                    maxZoom: 18,
                }
            ).addTo(locMap);

            const maxBounds = [
                [-90, -180], // Southwest corner (latitude, longitude)
                [90, 180], // Northeast corner (latitude, longitude)
            ];

            locMap.setMaxBounds(maxBounds);

            locMap.dragging.enable();
            locMapRef.current = locMap;
        }
        locMapRef.current.setView(
            [companyDetails?.latitude || 0, companyDetails?.longitude || 0],
            3
        );
        if (markersRef.current) {
            markersRef.current.clearLayers();
        } else {
            // Initialize the marker layer group
            markersRef.current = L.layerGroup(); // No addTo here
        }
        displayDetails.forEach((location) => {
            if(location.latitude===null || location.longitude===null) {
                return;
            }
            const marker = L.circleMarker(
                [location?.latitude, location?.longitude],
                {
                    radius: location?.suspicious === false ? 8 : 10,
                    fillColor: location?.suspicious === true ? "red" : "green",
                    color: location?.suspicious === true ? "red" : "green",
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 1,
                }
            );

            marker.bindPopup(
                `<div class="company-name"><a href="${location?.company_name_english}" target="_blank">${location?.company_name_english}</a></div>
                    <div class="popup-address">Address: ${location?.full_address}</div>`
            );

            marker.addTo(markersRef.current);
        });
        markersRef.current.addTo(locMap);
    },[displayDetails]);
    return (
        <>
            <div className='locs-map'>
                            {/* <div className='locs-map-header-container'> */}
                                {/* <div className='locs-map-header-text'>
                                    Company Locations{" "}
                                    <Tooltip
                                    title="This map is based off of relationships between entities, and does not necessarily represent the flow of a specific product between tiers"
                                    placement="right-start"
                                    arrow
                                    >
                                    <InfoIcon></InfoIcon>
                                    </Tooltip>
                                </div> */}

                                
                            {/* </div> */}
                            {/* <div className="map-locs-component-reset-zoom">
                                <Button
                                    className="map-locs-component-reset-zoom-button"
                                    variant="outlined"
                                    aria-label="zoom out map to original size button"
                                    startIcon={<ZoomOutMapIcon />}
                                    onClick={handleZoomOut}
                                >
                                    Reset Zoom
                                </Button>
                            </div> */}

                                    <div
                                        className="location-map-plot"
                                        id="location-map-id"
                                        style={{ height: "400px" }}
                                        // ref={locMapRef}
                                    ></div> 
                                    
                         
                        </div>
        </>
    );
}
export default CompanyLocationsMap;