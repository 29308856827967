import React, { useEffect, Fragment, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';

import RiskSpotlightComponent from "./RiskSpotlight";
import SupplyTraceInfoComponent from "./SupplyTraceInfo";
import { getHomepageDetailsThunk } from "../../services/homepage-thunk";
import CookiePopup from "./CookiePopup";
import { API_BASE } from "../../constants";

import axios from "axios";
import { axiosRequest } from "../../services/utils/axios";

import "./home.css";


const HomeScreen = () => {

  const dispatch = useDispatch();
  const nav = useNavigate();
  const riskSpotlightRef = useRef(null);

  let cancelToken;

  const { details, loading } = useSelector((state) => state.details);
  const { currentUser } = useSelector((state) => state.user);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    dispatch(getHomepageDetailsThunk());
    handleSearchInputChange(null, '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompanyChange = (_event, newValue) => {
    // Update the selected company value when a selection is made

    setSelectedCompany(newValue?.label);
    setSelectedCompanyId(newValue?.value)

  };

  const handleSearchInputChange = async (event, val) => {
    //const inputQuery = event?.target?.value;
    const inputQuery = val;
    setInputSearch(val);
    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    // Send the API request immediately with the inputQuery
    setSearchLoading(true);
    await axios
      .get(`${API_BASE}/searchcompaniesNew?search=${inputQuery}`, {
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        setSearchLoading(false);
        const responseData = response.data; // response.data is an array of search results
        setSearchResults(
          responseData.results?.map((array) => ({
            label: (array[1]),
            value: array[0],
          }))
        );
      })
      .catch((error) => {
        setSearchLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = async () => {
    try {
      if (window.umami) {
        let organisationType = currentUser?.organisation_type ?? "Search Without Login";
        organisationType = organisationType.split(' ')[0]
          window.umami.track('compSearch '+organisationType, {
            "company": selectedCompany+ " ("+selectedCompanyId +")",
          });
      } else {
          console.log("window.umami not there");
      }
    } catch (error) {
        console.error("An error occurred:", error);
    }
    nav(`/company/${selectedCompanyId}`);
    
  };

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {" "}
          <ReactLoading type="balls" color="#404040" height={100} width={50} />
        </div>
      )}

      {!loading && (
        <div className="homepage-container">
          
          <div id="top-id" className="homepage-top-container">

            <div className="homepage-header" tabIndex={0}>
              <img
                className="home-page-logo"
                src="logo/ST_OneLine_RGB.png"
                alt="our logo"
                tabIndex={0}
              ></img>
              <h3 className="homepage-logo-tagline">Open supply chain intelligence for a just world</h3>
            </div>

            <div className="homepage-card-and-data-container">
              <label htmlFor="combo-box-demo" className="home-page-search-label">
                Search apparel supply chains
              </label>
                <div className="datahub-card-container">
                  {/* <label htmlFor="combo-box-demo" className="label">
                    Search apparel supply chains
                  </label> */}
                  <div className="home-page-search">
                      <Autocomplete
                        className="home-page-search-auto-complete"
                        disablePortal
                        id="combo-box-demo"
                        options={searchResults}
                        sx={{ width: "100%" }}
                        value={selectedCompany || null} // Set the selected value
                        loading={loading}
                        onInputChange={handleSearchInputChange}
                        onChange={handleCompanyChange}
                        noOptionsText={
                          <Link href={`/advanced-trace/${encodeURIComponent(inputSearch)}`} target="_blank" rel="noopener">
                            Run Advanced Trace for {inputSearch}
                          </Link>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Search apparel supply chains"
                            placeholder="e.g., Jiangsu Guotai"
                            className="home-page-search-text-field"
                            aria-label="Search company auto complete box"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderRadius: "0px",
                                borderColor: "red",
                              },
                              endAdornment: (
                                <Fragment>
                                  {searchLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                  </div>
                  <div className="home-page-search-btn-container">
                    <Button
                      className="home-page-search-btn"
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search button"
                      variant="contained"
                      onClick={handleSearch}
                      disabled={!selectedCompany}
                    >
                      Trace
                    </Button>
                  </div>
                </div>
              <div className="datahub-card-advanced-search">
                {/* Not finding what you are looking for?{" "} */}
                <div className="datahub-card-advanced-search-button">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      nav("/advanced-trace");
                    }}
                  >
                    Advanced Trace
                  </Button>
                </div>
              </div>
            </div>
                    
            <div className="homepage-card-container">
              <div className="homepage-card cl-blue">
                <div className="homepage-card-image">
                  <img
                    width={100}
                    src="homepage/Companies.png"
                    alt="our logo"
                    tabIndex={0}
                  ></img>
                </div>
                <div
                  className="number-cards company-card"
                  tabIndex={0}
                  aria-label={`${details.data?.companies} companies`}>
                  <div className="number-text">
                    {" "}
                    {new Intl.NumberFormat("en-US", {}).format(
                      details.data?.companies
                    )}
                  </div>
                  <div>COMPANIES</div>
                </div>
              </div>
              <div
                className="homepage-card cl-red"
                tabIndex={0}
                aria-label={`${details.data?.relations} relations`}>
                <div className="homepage-card-image">
                  <img
                    width={100}
                    src="homepage/Relationship.png"
                    alt="our logo"
                    tabIndex={0}
                  ></img>
                </div>
                <div className="number-cards">
                  <div className="number-text">
                    {new Intl.NumberFormat("en-US", {}).format(
                      details.data?.relations
                    )}
                  </div>
                  <div>RELATIONSHIPS</div>
                </div>
              </div>
              <div
                className="homepage-card cl-orange"
                tabIndex={0}
                aria-label={`${details.data?.transactions} transactions`}>
                <div className="homepage-card-image">
                <img
                    width={100}
                    src="homepage/Transaction.png"
                    alt="our logo"
                    tabIndex={0}
                  ></img>
                </div>
                <div className="number-cards">
                  <div className="number-text">
                    {new Intl.NumberFormat("en-US", {}).format(
                      details.data?.transactions
                    )}
                  </div>
                  {/* <CountUp
                      duration={1.75}
                      className="number-text"
                      end={details.data?.transactions}
                      start={details.data?.transactions - 20}
                    /> */}
                  <div>TRANSACTIONS</div>
                </div>
              </div>
            </div>

            <IconButton ref={riskSpotlightRef} className="homepage-round-button" aria-label="delete" onClick={()=>{riskSpotlightRef.current.scrollIntoView()}}>
              <KeyboardArrowDownIcon />
            </IconButton>

          </div>
          
          <RiskSpotlightComponent  details={details} />
       
          <SupplyTraceInfoComponent />

          <CookiePopup />

        </div>
      )}
    </>
  );
};

export default HomeScreen;
