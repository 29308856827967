import React from 'react';
import { Box, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';

const IconToggle = ({ showMap, setShowMap }) => {
  const handleToggle = (value) => {
    setShowMap(value);
  };

  const containerStyle = {
    display: 'flex',
    // backgroundColor: 'white',
    borderRadius: '9999px',
    border: '2px solid black',
    // padding: '2px',
    width: 'fit-content',
  };

  const iconStyle = (isActive, isLeft) => ({
    backgroundColor: isActive ? 'black' : 'transparent',
    color: isActive ? 'white' : 'black',
    borderRadius: isLeft ? '9999px 0 0 9999px' : '0 9999px 9999px 0',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'center',
  });

  return (
    <Box style={containerStyle}>
      <Box onClick={() => handleToggle(false)} style={iconStyle(!showMap, true)}>
        <TableChartIcon />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Table
        </Typography>
      </Box>
      <Box onClick={() => handleToggle(true)} style={iconStyle(showMap, false)}>
        <MapIcon />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Map
        </Typography>
      </Box>
    </Box>
  );
};

export default IconToggle;