import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants";

/**
 * Fetches all company details for a given company.
 * 
 * @param {string} company - The company id.
 * 
 * @returns {Promise<Object>} Response containing detailed information about the company.
 * @returns {Object} response - Company details.
 * @returns {string} response.address - Address of the company.
 * @returns {boolean} response.source_investigation - Whether a source is investigation.
 * @returns {Object[]} response.top_suppliers - List of top suppliers with transaction details.
 * @returns {Object[]} response.top_customers - List of top customers with transaction details.
 * @returns {Object[]} response.top_acquired_products - List of top acquired products with HS codes.
 * @returns {Object[]} response.top_supplied_products - List of top supplied products with HS codes.
 * @returns {Object[]} response.year_data - Yearly transaction data (received and supplied).
 * @returns {Object[]} response.top_supplying_countries - Countries supplying to the company.
 * @returns {Object[]} response.top_buying_countries - Countries buying from the company.
 * @returns {Evidence[]} response.evidences - An object containing a list of Evidence objects
 * 
 * @example
 * // Response example:
 * {
 *   "address": "XYZ, xy Road,...",
 *   "source_investigation": false,
 *   "top_suppliers": [
 *     { "supplier": "CompanyX Co., Ltd.", "total_transactions": 15 },
 *     { "supplier": "CompanyY Co., Ltd.", "total_transactions": 2 },
 *     ...
 *   ],
 *   "top_customers": [
 *     { "customer": "CompanyA", "total_transactions": 1270 },
 *     { "customer": "CompanyB", "total_transactions": 572 },
 *     ...
 *   ],
 *   "top_acquired_products": [
 *     { "product": "Women’s or girls’ suits", "hscode": 6104, "count": 1 },
 *     { "product": "T-shirts, singlets...", "hscode": 6109, "count": 1 },
 *     ...
 *   ],
 *   "top_supplied_products": [
 *     { "product": "T-shirts, singlets", "hscode": 6109, "count": 205 },
 *     { "product": "Women’s or girls’ suits", "hscode": 6104, "count": 1 },
 *     ...
 *   ],
 *   "year_data": [
 *     { "year": 2024, "received_total_transactions": 0, "supplied_total_transactions": 39 },
 *     { "year": 2023, "received_total_transactions": 0, "supplied_total_transactions": 511 },
 *     ...
 *   ],
 *   "top_supplying_countries": [
 *     { "country_name": "China", "count": 18 },
 *     { "country_name": "United States", "count": 1 },
 *     ...
 *   ],
 *   "top_buying_countries": [
 *     { "country_name": "United States", "count": 894 },
 *     { "country_name": "Canada", "count": 38 },
 *     ...
 *   ],
 *   "evidences": [
 *     { "transaction_date": "2022-04-23", "source": "This company has an investment in XYZ.", "information_source": "Investigation" },
 *     { "transaction_date": "2018-11-30", "source": "ABC subsidiaries provided training to XYZ...", "information_source": "Investigation" },
 *     ...
 *   ]
 * }
 */
export const getAllDetails = async (companyId) => {
    const response = await axiosRequest.get(`${API_BASE}/getAllCompanyInfo/?search=${companyId}`);
    return response;
}