import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './index.css'
const YearBreakdownChart = ({ data }) => {
  return (
    <div>
      <div className="about-chart-container-header">
       Transactions Over Time
      </div>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          width={100}
          height={350}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="linear"
            dataKey="received_total_transactions"
            stroke="#8884d8"
            name="Received Transactions"
          />
          <Line
            type="linear"
            dataKey="supplied_total_transactions"
            stroke="#82ca9d"
            name="Supplied Transactions"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default YearBreakdownChart;