import React, { useState, useMemo } from 'react';
import TopBarChartComponent from './BarChartComponent';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
  } from "recharts";
const CountryChart = ({data}) => {
    return (
        <div>
                    <BarChart
                        width={450}
                        height={440}
                        data={data}
                        barGap={2}
                        barSize={55}
                      >
                        <XAxis
                          dataKey="country_name"
                          tick={{ fontSize: 10, width: 5 }}
                        />
                        <YAxis />
                        <Tooltip />

                        <Bar dataKey="count" fill="#00094b" />
                      </BarChart>
        </div>
    )
}
const CountryCharts = ({ company_info }) => {
  const [selectedOption, setSelectedOption] = useState('Suppliers');

  const filteredData = useMemo(() => {
    if(selectedOption === 'Suppliers') {
        return company_info?.top_suppliers
    } else {
        return company_info?.top_customers
    }
  }, [company_info, selectedOption]);

  return (
    <div className="w-full max-w-3xl border rounded-lg shadow-sm">
      <div className="flex flex-row items-center justify-between p-4 border-b">
        <h2 className="text-lg font-medium flex items-center">
          <span
            style={{color: '#0f73be',fontWeight: 'bold'}}          >
            Top 
          </span>
          
          <select 
            value={selectedOption} 
            onChange={(e) => setSelectedOption(e.target.value)}
            className="ml-2 p-1 border rounded text-primary-600 font-medium"
            style={{color: '#0f73be', backgroundColor: '#dddddd', fontWeight: 'bold'}}
          >
            <option value="Suppliers">Supplying</option>
            <option value="Customers">Acquiring</option>
          </select>
          <span
            style={{color: '#0f73be',fontWeight: 'bold'}}          >
            Countries 
          </span>
          
        </h2>
      </div>
      <div className="p-4">
      {
        selectedOption !== 'Suppliers' ? 
        <CountryChart data={company_info?.data?.top_buying_countries} /> :
        <CountryChart data={company_info?.data?.top_supplying_countries} />
       
      }
      </div>
    </div>
  );
};

export default CountryCharts;