import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RelationEntryFilterComponent from '../../RelationEntryFilterComponent';
import VisualisationsEntryComponent from '../../RelationshipVisualisationComponents/VisualisationEntryComponent';

import {getCustomerTransactions, getSupplierTransactions} from '../../../../services/company-details-service';

const LocationRelationshipComponent = ({ company}) => {

    // const { suppliersData, loading } = useSelector((state) => state.suppliersData);
    const [allSuppliersData, setAllSuppliersData] = useState()
    const [allCustomersData, setAllCustomersData] = useState()

    const [suppliersLoading, setSuppliersLoading] = useState(false)
    const [supplierCompanies, setSupplierCompanies] = useState()
    const [customerCompanies, setCustomerComapnies] = useState()

    const [currentMinYear, setCurrentMinYear] = useState(2014);
    const [currentMaxYear, setCurrentMaxYear] = useState(2014);
    const [companyName, setCompanyName] = useState("")
    const { currentUser } = useSelector((state) => state.user);

    const [supplierFilters, setSupplierFilters] = useState({
        draft: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
        applied: {
            tier: "3", year: { from: currentMinYear, to: currentMaxYear }
        },
    })

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 2;
        fetchData({ company: company, product: "ALL", startY: null, endY: null, tiers: 5, suppliers: [] })
        setCurrentMaxYear(previousYear);
        setCurrentMinYear(previousYear);
    }, [company])
    useEffect(()=> {
        console.log(allSuppliersData,"pop changien")
    },[allSuppliersData])
    
    const fetchData = async (filterQuery) => {
        try {
            setSuppliersLoading(true)
            const supplier_response = await getSupplierTransactions(filterQuery);

             // If response status is 401, redirect to login
            if (supplier_response.status === 401) {
                    // Redirect to login page
                window.location.href = '/login';
                return; // Stop further execution
            }
            const customer_response = await getCustomerTransactions(filterQuery);

            setCompanyName(supplier_response?.data?.company?.company_name_english)   
            setCurrentMinYear(Math.min(supplier_response?.data?.startY, customer_response?.data?.startY));
            setCurrentMaxYear(Math.max(supplier_response?.data?.endY, customer_response?.data?.endY));
            const filterStatus = "applied"
            const filters = {
                ...supplierFilters,
                [filterStatus]: {
                    ...supplierFilters[filterStatus],
                    year: {
                        from: Math.min(supplier_response?.data?.startY, customer_response?.data?.startY),
                        to: Math.max(supplier_response?.data?.endY, customer_response?.data?.endY)
                    }
                }
            }
            setSupplierFilters(filters)
            const supplier_finalDictList = supplier_response?.data?.tiers?.flatMap(({ tier, shippers }) => ({ tier, shipperName: shippers.map(({ company_name_english }) => company_name_english) }));
            setAllSuppliersData(supplier_response);
            setAllCustomersData(customer_response);
            setSupplierCompanies(supplier_finalDictList);
            console.log(supplier_finalDictList,"ck1")
            const customer_finalDictList = customer_response?.data?.tiers?.flatMap(({ tier, consignee }) => ({ tier, shipperName: consignee.map(({ company_name_english }) => company_name_english) }));
            setCustomerComapnies(customer_finalDictList)
            setSuppliersLoading(false);
            try {
                if (window.umami) {
                    let organisationType = currentUser?.organisation_type;
                    organisationType = organisationType.split(' ')[0];
                    window.umami.track('SupplierLoad '+organisationType, {
                        "searchParams": 
                        supplier_response?.data?.company?.company_name_english
                        +"/"+company+ "/"+filterQuery?.startY+"/"+filterQuery?.endY,
                    });
                } else {
                    console.log("window.umami not there");
                }
              } catch (error) {
                  console.error("An error occurred:", error);
            }
            return "Success";
        }
        catch (error) {
            console.log(error);
            setSuppliersLoading(false)
        }
    }

    const handleFilterSelection = async (appliedFilter) => {
        const filterQuery = {
            company: company,
            product: "ALL",
            startY: appliedFilter.year.from,
            endY: appliedFilter.year.to,
            tiers: "5"
        }
        setCurrentMinYear(appliedFilter.year.from);
        setCurrentMaxYear(appliedFilter.year.to);
        
        await fetchData(filterQuery)
    }

    return (
        <>
            {suppliersLoading && 
                <>
                    <Grid container wrap="nowrap">
                        {Array.from(new Array(2)).map((item, index) => (
                            <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                                <Skeleton variant="rectangular" width={"90%"} height={320} />
                            </Box>
                        ))}
                    </Grid>
                    <Grid container wrap="nowrap">
                        {Array.from(new Array(2)).map((item, index) => (
                            <Box key={index} sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}>
                                <Skeleton variant="rectangular" width={"90%"} height={320} />
                            </Box>
                        ))}
                    </Grid>
                </>
            }
            



            <div className='supplier-main-container'>
            <h3 style={{textAlign: "center", padding: "1rem"}}>Relationships of {companyName}</h3>

                <div className='supplier-component-filter-container'>
                    <RelationEntryFilterComponent
                        supplierFilters = {supplierFilters} 
                        setSupplierFilters = {setSupplierFilters}
                        numberOfTiers={allSuppliersData?.data.tiers.length}
                        countries={[...new Set(allSuppliersData?.data.tiers.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.supplier_countries.split(",")], []))]}
                        handleFilterSelection={handleFilterSelection}
                    ></RelationEntryFilterComponent>
                </div>
                {
                    !suppliersLoading && (!allSuppliersData?.data?.has_suppliers && !allCustomersData?.data?.has_customers) && (
                        <>
                        <div className='no-suppliers'>
                            No Relationships Found based on filter parameters
                        </div>
                    </>
                  )
                }
                {!suppliersLoading && (allSuppliersData?.data?.has_suppliers || allCustomersData?.data?.has_customers) && (
                    <div className='supplier-data-container' >
                        
                        <VisualisationsEntryComponent 
                            allSuppliersData={allSuppliersData} 
                            allCustomersData={allCustomersData} 
                            supplier_companies={supplierCompanies} 
                            customer_companies={customerCompanies} 
                            company_name={companyName} 
                            handleFilterSelection={handleFilterSelection} 
                            is_customer={false} />
                    </div>
                )}
            </div >

        </>
    )
}

export default LocationRelationshipComponent;