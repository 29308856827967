import React, { useState, useEffect } from 'react';

import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Download, Bookmark, Info } from 'lucide-react';

import './index.css';

const CompanyHeader = ({company_name, company_address}) => {
  const [activeTab, setActiveTab] = useState('relationships');

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash === 'locations') {
        setActiveTab('locations')
      } else if (hash === 'dashboard') {
        setActiveTab('dashboard')
      } else {
        setActiveTab('relationships')
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Check initial hash

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <Navbar className="flex-column align-items-stretch border-bottom company-page-header">

      <Container fluid className="px-3">
        <div className="d-flex justify-content-between align-items-center w-100 py-2">
          <div>
            <div className="d-flex align-items-center m-1">
              <span className="fw-bolder" style={{fontSize: '1.6rem'}}>{company_name}</span>
            </div>
            <div className="d-flex align-items-center m-1">
              <span className="fw-bold" style={{fontSize: '0.8rem'}}>{company_address}</span>
            </div>
          </div>
          {/* <div>
            <Button variant="link" className="p-1 me-2">
              <Download size={20} />
            </Button>
            <Button variant="link" className="p-1 me-2">
              <Bookmark size={20} />
            </Button>
            <Button variant="link" className="p-1">
              <Info size={20} />
            </Button>
          </div> */}
        </div>
      </Container>

      <Container fluid className="px-3 mt-2">
        <Nav className="custom-tabs">
          <Nav.Item>
            <Nav.Link 
              href="#dashboard" 
              className={`custom-tab ${activeTab === 'dashboard' ? 'active' : ''}`}
              onClick={() => setActiveTab('dashboard')}
              style={{fontSize : '1.2rem'}}
            >
              Overview
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link 
              href="#relationships" 
              className={`custom-tab ${activeTab === 'relationships' ? 'active' : ''}`}
              onClick={() => setActiveTab('relationships')}
              style={{fontSize : '1.2rem'}}
            >
              Relationships
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link 
              href="#locations" 
              className={`custom-tab ${activeTab === 'locations' ? 'active' : ''}`}
              onClick={() => setActiveTab('locations')}
              style={{fontSize : '1.2rem'}}
            >
              Locations
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>

    </Navbar>
  );
};

export default CompanyHeader;