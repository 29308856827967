import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userVerified: false,
        credentials: {
            username: null,
            password: null
        }
    },
    reducers: {
        setUserVerified: (state, action) => {
            state.userVerified = action.payload;
        },
        setCredentials: (state, action) => {
            state.credentials = {
                username: action.payload.username,
                password: action.payload.password
            };
        }
    }
});

export const { setUserVerified, setCredentials } = authSlice.actions;
export default authSlice.reducer;