import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDetails } from "./about-page-service"; 


export const getAllCompanyInfoThunk = createAsyncThunk(
    'get_all_company_info',
    async ({ company }, { rejectWithValue }) => {
        try {
            console.log(company)
            const response = await getAllDetails(company);
            return response.data;
          } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle the 401 error specifically
                return rejectWithValue({
                  status: 401,
                  message: 'Unauthorized',
                  companyAddress: error.response.data.company.full_address
                });
              }
              return rejectWithValue({
                status: error.response ? error.response.status : 500,
                message: error.message || 'Something went wrong'
              });
          }
    }
)