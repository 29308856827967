export const filterByName = (allRelations,supplierIntersect, is_customer) => {
    return allRelations.filter(relation => {
        if(is_customer) {
            return supplierIntersect.includes(relation.to_company__company_name_english)
        } else {
            return supplierIntersect.includes(relation.from_company__company_name_english)
        }
    });
};
export const filterByCountry = (allRelations,countries) => {
  console.log(countries)
  return allRelations.filter(relation => {
    const toCountry = relation.to_company__country__name;
    const fromCountry = relation.from_company__country__name;
    return (toCountry && countries.includes(toCountry)) || 
           (fromCountry && countries.includes(fromCountry));
  });
};
export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }
  
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepCopy(value)])
    );
};

export const filterRelations = (is_customer,company_name, suppliers, allSuppliersData) => {
    var allRelations = []
    const suppliersData = deepCopy(allSuppliersData);
    suppliersData.data.tiers.forEach((tier) => {
        if(is_customer) {
            allRelations.push(...tier.to_relations);
        } else {
            allRelations.push(...tier.from_relations);
        }
    })
    var visitedSuppliers = new Set(suppliers);
    let supplierIntersect = suppliers  // Initialize with your initial supplier_intersect data
    let filteredRelation_ids = [];  // Initialize with your array of objects
    while (supplierIntersect.length > 0) {

        let cr = filterByName(allRelations, supplierIntersect, is_customer);
        filteredRelation_ids.push(...cr.map(rel => rel.id));
        var newSuppliers = new Set();
        if(is_customer) {
            newSuppliers = new Set(cr.map(relation => relation.from_company__company_name_english));
        } else {
            newSuppliers = new Set(cr.map(relation => relation.to_company__company_name_english));
        }
        newSuppliers = new Set([...newSuppliers].filter(supplier => !visitedSuppliers.has(supplier)));

        newSuppliers.delete(company_name);

        supplierIntersect = [...newSuppliers];
        visitedSuppliers = new Set([...visitedSuppliers, ...newSuppliers]);
    }

    var tierCount = suppliersData.data.tiers.length;
    for(var i=0;i<tierCount;i++) {
        if(is_customer) {
            suppliersData.data.tiers[i].to_relations = suppliersData.data.tiers[i].to_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))
        } else {
            suppliersData.data.tiers[i].from_relations = suppliersData.data.tiers[i].from_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))
        }
    }
    return suppliersData;
}
export const filterRelationsByCountry = (countries, allSuppliersData, limitedData) => {
  var allRelations = []
  console.log(allSuppliersData)
  const suppliersData = deepCopy(allSuppliersData);
  suppliersData.data.tiers.forEach((tier, index) => {
          if(index>0) {
            return;
          }
          console.log(index, "toels")
          allRelations.push(...tier.to_relations);
          allRelations.push(...tier.from_relations);
  })
  let filteredRelation_ids = [];  // Initialize with your array of objects
  console.log(allRelations)
  let cr = filterByCountry(allRelations, countries);
  console.log(cr)
  filteredRelation_ids.push(...cr.map(rel => rel.id));
  console.log(cr, filteredRelation_ids, 'sksk')
  var tierCount = suppliersData.data.tiers.length;
  for(var i=0;i<tierCount;i++) {
      suppliersData.data.tiers[i].to_relations = suppliersData.data.tiers[i].to_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))
      limitedData.data.tiers[i].to_relations = suppliersData.data.tiers[i].to_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))

      suppliersData.data.tiers[i].from_relations = suppliersData.data.tiers[i].from_relations.filter((rel)=> 
        {
        return filteredRelation_ids.includes(rel.id)})
      limitedData.data.tiers[i].from_relations = suppliersData.data.tiers[i].from_relations.filter((rel)=> filteredRelation_ids.includes(rel.id))

      console.log(i,suppliersData.data.tiers[i].from_relations.length)
  }
  console.log(suppliersData.data)

  return [suppliersData, limitedData];
}
export const plot_line_color = (from_id, to_id, suspicious_level_dictionary) => {
    
    var from_level = -1;
    var to_level = -1;
    for (
      var i = 0;
      i < Object.keys(suspicious_level_dictionary).length;
      i++
    ) {
      var hasFromCompany = suspicious_level_dictionary[i].some(function (
        company
      ) {
        return (
          from_id === company
        );
      });
      var hasToCompany = suspicious_level_dictionary[i].some(function (
        company
      ) {
        return (
          to_id === company
        );
      });
      if (hasFromCompany && from_level===-1) {
        from_level = i;
      }
      if (hasToCompany && to_level===-1) {
        to_level = i;
      }
      
    }
    return [from_level, to_level];
  }
  export const filterSuspiciousData = (details, is_customer, sus_relations) => {
    const detailsCopy = JSON.parse(JSON.stringify(details));
    for(var i=0; i<detailsCopy.tiers?.length; i++) {
      var tier =  detailsCopy.tiers[i]
      var relationships = is_customer
        ? tier.to_relations
        : tier.from_relations;
      let newr = relationships.filter((relation) => {
          var from_id = relation.from_company__id;
          var to_id = relation.to_company__id;
          const [from_level, to_level] = plot_line_color(from_id, to_id, sus_relations);
          if (from_level === -1 || to_level === -1) {
            return false;
          } else {
            return true;
          }
      });
      console.log(newr, "tier")
      if(is_customer) {
        tier.to_relations = newr
      } else {
        tier.from_relations = newr
      }
      detailsCopy.tiers[i] = tier
      console.log( detailsCopy.tiers[i], "tier")
    }
    console.log(detailsCopy);
    return detailsCopy;
  }
export function processEntityData(allEntityData, isCustomer, selectedTierFilter) {
    const entityData = deepCopy(allEntityData);
    const tiers = entityData?.data?.tiers;
    let prev = [];

    for (let tierIndex = 1; tierIndex <= tiers?.length; tierIndex++) {
        const tier = tiers[tierIndex - 1];
        let relationships = isCustomer ? tier.to_relations : tier.from_relations;

        if (tierIndex > parseInt(selectedTierFilter, 10)) {
            break;
        }

        if (relationships.length > 50) {
            relationships = relationships.sort((a, b) => {
                const aSuspicious = a.from_company__suspicious || a.to_company__suspicious;
                const bSuspicious = b.from_company__suspicious || b.to_company__suspicious;
                return bSuspicious - aSuspicious;
            });

            if (prev.length > 0) {
                relationships = relationships.filter((r) =>
                    isCustomer ? prev.includes(r.to_company__id) : prev.includes(r.from_company__id)
                );
            } else {
                relationships = relationships.slice(0, 50);
            }

            prev = [
                ...prev,
                ...relationships.map((r) =>
                    isCustomer ? r.to_company__id : r.from_company__id
                )
            ];
        }

        if (isCustomer) {
            entityData.data.tiers[tierIndex - 1].to_relations = relationships;
        } else {
            entityData.data.tiers[tierIndex - 1].from_relations = relationships;
        }
    }

    return entityData;
}
export const fetchData = (entityType, filterQuery,company_name, allEntityData, limitedEntityData, isCustomer, setEntityLoading, setSelectedEntities, setEntityData, setSelectedTierFilter) => {
  try {
      setEntityLoading(true);
      setSelectedEntities(filterQuery[entityType]);
      if (filterQuery[entityType].length > 0) {
          const filteredData = filterRelations(isCustomer, company_name, filterQuery[entityType], allEntityData);
          if (filterQuery.is_sus) {
              filteredData.data = filterSuspiciousData(allEntityData?.details, isCustomer, allEntityData?.data?.sus_relations);
          }
          setEntityData(filteredData);
      } else {
          const filteredData = deepCopy(limitedEntityData);
          if (filterQuery.is_sus) {
              const suspiciousData = filterSuspiciousData(allEntityData?.data, isCustomer, allEntityData?.data?.sus_relations);
              filteredData.data = suspiciousData;
          }
          console.log(filteredData, "lol")
          setEntityData(filteredData);
      }
      setSelectedTierFilter(filterQuery.tiers);
      setEntityLoading(false);
  } catch (error) {
      console.error(error);
      setEntityLoading(false);
  }
};


// Function to process supplier and customer details
export const processDetails = (supplierDetails, customerDetails) => {
  const allDetails = [supplierDetails, customerDetails];

  var relationshipsTable = []
  const uniqueLinkSet = new Set();
  allDetails.forEach(details => {
      if (!details) {
          return;
      }
      const tiers = details.tiers;
      console.log("herek")
      for (let tierIndex = 1; tierIndex <= tiers.length; tierIndex++) {
          const tier = tiers[tierIndex - 1];
          const relationships = details === supplierDetails ? tier.from_relations : tier.to_relations;
          
          relationships.forEach((relationship) => {
              const fromCompanyId = relationship.from_company__id;
              const toCompanyId = relationship.to_company__id;
              if (fromCompanyId == null || toCompanyId == null) {
                  return;
              }
              const linkKey = `${fromCompanyId}-${toCompanyId}`;
              if (!uniqueLinkSet.has(linkKey)) {
                  uniqueLinkSet.add(linkKey);

                  const link = {
                      id: `${fromCompanyId}-${toCompanyId}`,
                      fromCompanyId: fromCompanyId,
                      fromCompanyName: relationship.from_company__company_name_english,
                      toCompanyId: toCompanyId,
                      toCompanyName: relationship.to_company__company_name_english,
                      totalTransactions: relationship.total_transactions
                  };

                  if (fromCompanyId !== toCompanyId) {
                      relationshipsTable.push(link);
                      console.log("sk")
                  }

              }
          });
      }
  });
  console.log(relationshipsTable,"skk");
  return relationshipsTable;
};

