import React, { useState, useRef, useEffect } from 'react';
import './index.css';
import { 
  Grid, 
  Switch, 
  Typography, 
  Button, 
  Popover, 
  TextField, 
  MenuItem, 
  Box 
} from "@mui/material";
import CompanyLocationsMap from "./CompanyLocationsMap";
import LocationsGridComponent from "./CompanyLocationsGrid";
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import FilterListIcon from '@mui/icons-material/FilterList';
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import IconToggle from './IconToggle';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: red[600],
      '&:hover': {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: red[600],
    },
  }));
const CompanyLocations = ({ companyLocations, companyDetails, setSelectedLocation }) => {
    const [showMap, setShowMap] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterValues: [],
    });
    const [tempFilter, setTempFilter] = useState({
        field: '',
        operator: '',
        value: '',
    });

    const valueInputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (isFocused && valueInputRef.current) {
            valueInputRef.current.focus();
        }
    }, [tempFilter, isFocused]);

    const handleToggleMap = () => {
        setShowMap(!showMap);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setTempFilter({
            field: filterModel.items[0]?.field || '',
            operator: filterModel.items[0]?.operator || '',
            value: filterModel.items[0]?.value || '',
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsFocused(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    const handleTempFilterChange = (field, value) => {
        setTempFilter(prev => ({ ...prev, [field]: value }));
        if (field === 'value') {
            setIsFocused(true);
        }
    };

    const handleApplyFilter = () => {
        if (tempFilter.field && tempFilter.operator && tempFilter.value) {
            setFilterModel({
                ...filterModel,
                items: [tempFilter],
            });
        } else {
            setFilterModel({
                ...filterModel,
                items: [],
            });
        }
        handleClose();
    };

    const columns = [
        { field: 'company_name_english', headerName: 'Location' },
        { field: 'full_address', headerName: 'Address' },
    ];

    const CustomFilterComponent = () => (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                select
                label="Field"
                value={tempFilter.field}
                onChange={(e) => handleTempFilterChange('field', e.target.value)}
            >
                {columns.map((column) => (
                    <MenuItem key={column.field} value={column.field}>
                        {column.headerName}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                label="Operator"
                value={tempFilter.operator}
                onChange={(e) => handleTempFilterChange('operator', e.target.value)}
            >
                <MenuItem value="contains">Contains</MenuItem>
                <MenuItem value="equals">Equals</MenuItem>
                <MenuItem value="startsWith">Starts with</MenuItem>
                <MenuItem value="endsWith">Ends with</MenuItem>
            </TextField>
            <TextField
                label="Value"
                value={tempFilter.value}
                onChange={(e) => handleTempFilterChange('value', e.target.value)}
                inputRef={valueInputRef}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleApplyFilter}
            >
                Apply Filter
            </Button>
        </Box>
    );
    const [suspiciousOnly, setSuspiciousOnly] = useState(false)
    const setSus = () => {
        setSuspiciousOnly((prevSuspiciousOnly) => {      
          return !prevSuspiciousOnly;
        });

    };
    return (
        <div className='locs-map'>
            <div className='locs-map-header-container'>
                <Typography variant="h5" color="primary" style={{ flexGrow: 1 }}>
                    Company Locations
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!showMap ? 
                        <div style={{marginRight: '1rem'}}>
                            <Button 
                                startIcon={<FilterListIcon />}
                                style={{ 
                                    padding: '5px 20px 5px 20px',
                                    color: 'black', 
                                    border: '1px solid black'
                                }}
                                onClick={handleClick}
                            >
                                Filter
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <CustomFilterComponent />
                            </Popover>
                        </div> : 
                        <div style={{    marginRight: '1rem'}}>
                            <div className='locs-map-container-suspicious-filter'>
                                        <div className='locs-filter-name-text'>
                                            Risk Only
                                        </div>
                                        <RedSwitch
                                            
                                            checked={suspiciousOnly}
                                        
                                            onChange={setSus}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                            </div>
                        </div>
                    
                        }
                    
                    <IconToggle showMap={showMap} setShowMap={setShowMap} />
                </div>
            </div>
            {showMap ? (
                <CompanyLocationsMap companyDetails={companyDetails} companyLocations={companyLocations} />
            ) : (
                <LocationsGridComponent 
                    locations={companyLocations} 
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    setSelectedLocation={setSelectedLocation}
                />
            )}
        </div>
    );
}

export default CompanyLocations;