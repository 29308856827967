//export const API_BASE = "http://129.10.0.56:8082/api"

export const API_BASE =  process.env.REACT_APP_API_BASE


// API KEYS
// export const GOOGLE_RECAPTCHA_SITE_KEY = "6Lf9r4YpAAAAAGJ-U6ViprafmzMbXokt4cYV_wL1"
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY


// FEEDBACK PAGE
export const FEEDBACK_OPTIONS = ['Website','Data','Partnerships','Feedback','Other']

export const FEEDBACK_PLACEHOLDER = "Thank you for getting in touch. We are currently building and improving the site. Please provide a detailed message and we will get back to you"

// RISK SPOTLIGHT
export const RISK_SPOTLIGHT_LAT_LNG = process.env.REACT_APP_RISK_SPOTLIGHT_LAT_LNG.split(',').map(Number);

export const RISK_SPOTLIGHT_CONTENT = "Supply Trace is an accessible, open platform that exposes potential risks in global supply chains. In its pilot phase, it focuses on the apparel sector and links between forced labor in the Uyghur Region of China and shipments to the US. This risk data has been provided by a team of experts at the Sheffield Hallam University Forced Labor Lab."



// tag line
export const TAG_LINE = "Open supply chain intelligence for a just world";

// LOGIN PATE
export const LOGIN_PAGE_HEADING = "Supply Trace";
export const LOGIN_PAGE_PARAGRAPH = "Supply Trace is an accessible, open platform that exposes potential risks in global supply chains. It is a research project incubated at Northeastern University.";

// ADVANCE SEARCH PAGE
export const ADVANCE_SEARCH_RISK_LEGEND = "- Potential Supply Chain Risk";
export const ADVANCE_SEARCH_HEADING = "Advanced Trace";

// SUPPLY TRACE INFO

export const SUPPLYTRACE_INFO_HEADING = "What is Supply Trace?";

export const SUPPLYTRACE_INFO_CONTENT = " Supply Trace is populated with customs records, such as shipping and bill of lading data, and other publicly available supply chain information. Additional intelligence, derived from desk-based research, is layered over the base supply chain data. In the pilot phase, this research has been led by a team of Uyghur nationals, with deep subject matter expertise relating to issues of forced labor in the Uyghur Region of China. In the future, Supply Trace will progressively expand to other industries, affected regions and categories of risk.";

// SUPPLY TRACE CARD

export const SUPPLYTRACE_CARD1_HEADING = "An open platform for all";
export const SUPPLYTRACE_CARD2_HEADING = "Automated supply chain tracing";
export const SUPPLYTRACE_CARD3_HEADING = "Deep subject matter expertise";

export const SUPPLYTRACE_CARD1_IMG = "homepage/search-bar.png";
export const SUPPLYTRACE_CARD2_IMG = "homepage/map-info.png";
export const SUPPLYTRACE_CARD3_IMG = "homepage/metrics-info.png";


export const SUPPLYTRACE_CARD1_CONTENT = "Supply Trace is available for anyone to search who has an interest in better understanding supply chain conditions, including companies, government, civil society and more.";
export const SUPPLYTRACE_CARD2_CONTENT = "Machine learning automates the process of tracing connections between multiple supply chain tiers, as goods flow through global supply chains.";
export const SUPPLYTRACE_CARD3_CONTENT = "Supply Trace is a research project incubated at Northeastern University. In the pilot phase, it combines the forced labor expertise of Northeastern and Sheffield Hallam Universities, with programming and computational resources provided by Northeastern.";

// COMPANY MAP INFO

export const COMPANY_MAP_INFO = "Supply Trace is a research project incubated at Northeastern University. In the pilot phase, it combines the forced labor expertise of Northeastern and Sheffield Hallam Universities, with programming and computational resources provided by Northeastern.";

export const COMPANY_SANKEY_INFO = "The flow chart below illustrates potential supply chain connections using a Sankey diagram. You are currently viewing the most frequent 25 supplier links to entity_name for the years start_year to end_year. To change the years, filter for a specific supplier, or view potential risk only, update the filters below.";

// COMPANY EVIDENCE SHU TAGLINE

export const SHU_INFO = "Potential risk data added by the Sheffield Hallam University Forced Labor Lab"

// COMPANY SUMMARY

export const DASHBOARD_SUMMARY_TEMPLATE = '[#] shipments traced to or from [#loc] locations from years [first_year] to [end_year].'
export const SUMMARY_NOTE = 'Please note: The connections are generated using a MACHINE LEARNING algorithm that is not yet fully complete or optimized. Please read our Terms and Conditions for additional data usage information'
export const CUSTOMER_SUMMARY_TEMPLATE = "For the entity [Entity Name], you are currently viewing a trace of [#] customers. For the years [first_year]to [end_year], there [risk] potential risk detected in this entity's supply chain, please use the filter box above to widen or shorten the trace timeframe. We will be adding additional data on a continuous basis to this entity trace. Use the interactive map below to explore the entity's supply chain relationships!"
export const SUPPLIER_SUMMARY_TEMPLATE = "For the entity [Entity Name], you are currently viewing a trace of [#s] suppliers and [#c] customers. For the years [first_year] to [end_year], there [risk] potential risk detected in this entity's supply chain. Please use the filter box below to increase or decrease the Trace timeframe. Use the interactive map to explore the entity's supply chain relationships. We will be adding additional data on a continuous basis to Supply Trace, so keep checking back for updates."