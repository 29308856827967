import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';

import { advancedSearchCompanies } from '../../services/homepage-service';
import "./index.css";

const fetchData = async (page, rowsPerPage, search, searchMethod) => {
  try {
    const searchParams = {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      search: search,
      searchMethod: searchMethod,
    };
    const response = await advancedSearchCompanies(searchParams);
    console.log(response)
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const AdvanceSearchResultsComponent = ({ searchText, searchMethod }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [search, setSearch] = useState(searchText);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        setLoading(true);
        const response = await fetchData(page, rowsPerPage, searchText, searchMethod);
        setData(response.data.results);
        setTotalPages(Math.ceil(response.data.count / rowsPerPage));
        setTotalCount(response.data.count);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndSetState();
  }, [page, rowsPerPage, searchText, searchMethod]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <div className='loader-container'><CircularProgress /></div>;
  }
    
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><h4>Name</h4></TableCell>
              <TableCell><h4>Address</h4></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow className={(row.suspicious === false || row.suspicious === null) ? '' : 'advance-search-item-sus'} key={row.id}>
                <TableCell> <Link to={`/company/${row.id}`}>{row.company_name_english}</Link></TableCell>
                <TableCell>{row.full_address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
    
export default AdvanceSearchResultsComponent;