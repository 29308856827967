import axios from "axios";
import { axiosRequest } from "./utils/axios";
import { API_BASE } from "../constants";

/**
 * Authenticates a user.
 * 
 * @param {Object} user - The user credentials
 * @param {string} user.username - User's email address
 * @param {string} user.password - User's password
 * @param {string} user.g-recaptcha-response - Google reCAPTCHA response token
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.access - JWT access token
 * @returns {string} response.refresh_token - JWT refresh token
 * @returns {number} response.userId - User's id
 * 
 * @example
 * // Request payload example:
 * const user = {
 *   username: "user@northeastern.edu",
 *   password: "password",
 *   "g-recaptcha-response": "03AFcWeA6raVIL..."
 * };
 * 
 * // Response example:
 * {
 *   access: "eyJhbGciOiJIUzI1NiIs...",
 *   refresh_token: "eyJhbGciOiJIUzI1NiIs...",
 *   userId: 224
 * }
 */
export const login = async (user) => {
  const response = await axiosRequest.post(`${API_BASE}/signin/`, user);
  return response;
};

/**
 * Fetches the current user's profile information.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.username - User's email address.
 * @returns {number} response.id - User's id.
 * @returns {string} response.organisation_type - Type of organization the user belongs to.
 * @returns {string[]} response.groups - List of groups the user belongs to.
 * 
 * @example
 * // Response example:
 * {
 *   "username": "user@northeastern.edu",
 *   "id": 224,
 *   "organisation_type": "Academic / Researcher / Journalist / Student",
 *   "groups": [
 *     "merge_requesters",
 *     "merge_approvers"
 *   ]
 * }
 */
export const getUser = async () => {
  const response = await axiosRequest.get(`${API_BASE}/user/`);
  return response.data;
};

/**
 * Validates a token for a specified user.
 * 
 * @param {string} token - The token to be validated.
 * @param {string} userId - The email address of the user associated with the token.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.message - Status message of the token validation (e.g., "Invalid token.").
 * 
 * @example
 * // Response example:
 * {
 *   "message": "Invalid token."
 * }
 */
export const validateToken = async (token, userId) => {
  try {
    const response = await axios.get(
      `${API_BASE}/validatetoken?token=${token}&id=${userId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Initiates the password reset process by sending a reset email to the specified user.
 * 
 * @param {Object} resetData - The data required for resetting the password.
 * @param {string} resetData.userId - The email address of the user requesting the password reset.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.message - Success message confirming the email was sent.
 * 
 * @example
 * // Request payload example:
 * const resetData = {
 *   userId: "user@northeastern.edu"
 * };
 * 
 * // Response example:
 * {
 *   "message": "MAIL SENT"
 * }
 */
export const mailResetPasswordLink = async (userData) => {
  const response = await axios.post(`${API_BASE}/reset-password/`, { "userId": userData.email });
  return response;
};

/**
 * Resets the user's password using a token and new password.
 * 
 * @param {Object} resetData - The data required for resetting the password.
 * @param {string} resetData.token - The token for password reset verification.
 * @param {string} resetData.userId - The email address of the user resetting their password.
 * @param {string} resetData.newPassword - The new password to set for the user.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.message - Success message confirming the password reset.
 * 
 * @example
 * // Request payload example:
 * const resetData = {
 *   token: "91270",
 *   userId: "user@northeastern.edu",
 *   newPassword: "password"
 * };
 * 
 * // Response example:
 * {
 *   "message": "Password reset successfully!"
 * }
 */
export const resetPassword = async (resetData) => {
  try {
    const response = await axios.post(`${API_BASE}/change-password`, resetData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Sends an OTP to the specified user for verification.
 * 
 * @param {string} username - The username or email address of the user to send the OTP to.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.data - Confirmation message for OTP dispatch.
 * 
 * @example
 * // Request payload example:
 * const username = "user@northeastern.edu";
 * 
 * // Response example:
 * {
 *   "data": "OTP Sent"
 * }
 */
export const sendOTP = async (username) => {
  try {
    const response = await axios.post(`${API_BASE}/send-otp`, { userId: username });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Registers a new user.
 * 
 * @param {Object} registrationData - The user data required for user registration.
 * @param {string} registrationData.username - User's email address.
 * @param {string} registrationData.password - User's password.
 * @param {string} registrationData.organisation_type - Type of organization the user is affiliated with.
 * @param {string} registrationData.g-recaptcha-response - Google reCAPTCHA response token.
 * @param {boolean} registrationData.accept_tandc - Indicates acceptance of terms and conditions.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.message - Success message upon successful registration.
 * 
 * @example
 * // Request payload example:
 * const registrationData = {
 *   username: "user@northeastern.com",
 *   password: "password",
 *   organisation_type: "Academic / Researcher / Journalist / Student",
 *   "g-recaptcha-response": "03AF6...",
 *   accept_tandc: true
 * };
 * 
 * // Response example:
 * {
 *   "message": "User registered successfully"
 * }
 */
export const register = async (registrationData) => {
  const response = await axios.post(`${API_BASE}/register/`, registrationData);
  return response;
};

/**
 * Validates the OTP for user verification.
 * 
 * @param {Object} otpData - The data required to validate the OTP.
 * @param {string} otpData.userId - The user's email address or identifier.
 * @param {string} otpData.otp - The OTP code to be validated.
 * 
 * @returns {Object} response - The response object.
 * @returns {string} response.data - Success message upon successful OTP validation.
 * 
 * @example
 * // Request payload example:
 * const otpData = {
 *   userId: "user@northeastern.edu",
 *   otp: "433852"
 * };
 * 
 * // Response example:
 * {
 *   "data": "User Verified"
 * }
 */
export const validateOtp = async (otpData) => {
  const response = await axios.post(`${API_BASE}/validate-otp`, {"userId": otpData.username, "otp": otpData.otp});
  return response;
};