import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';

import RelationshipComponent from './RelationshipsComponents/RelationshipComponent';
import DashboardComponent from './DashboardComponent';
import CompanyHeader from './CompanyHeaderComponent';
import CompanyLocationsEntryComponent from './CompanyLocations';
import Footer from '../Footer';

import {getInfo} from '../../services/company-details-service';

import './index.css';

const CompanyScreen = () => {

    const { companyId } = useParams();
    const { hash } = useLocation();

    const [ companyAddress, setCompanyAddress ] = useState("");
    const [ companyName, setCompanyName ] = useState("");
    const [ companyVisible, setCompanyVisible ] = useState(true);
    const { currentUser } = useSelector((state) => state.user);

    useEffect(()=> {
        if(companyName.length > 0){
            document.title = companyName;
        }
    },[companyName]);

    useEffect(() => {
        fetchCompanyInfo()
    }, [companyId]);

    const fetchCompanyInfo = async () => {
        try {
            const response = await getInfo(companyId);
            setCompanyName(response?.data?.company_name_english)
            setCompanyAddress(response?.data?.full_address)
            setCompanyVisible(response?.data?.visible)
            try {
                if (window.umami) {
                    let organisationType = currentUser?.organisation_type ?? "unknown";
                    organisationType = organisationType.split(' ')[0];
                    window.umami.track('companyLoad#'+organisationType, {
                        "company": response?.data?.company_name_english + " (" + companyId+ ")",
                    });
                } else {
                    console.log("window.umami not there");
                }
              } catch (error) {
                  console.error("An error occurred:", error);
              }
        }
        catch (error) {
            if (error.response && error.response.status === 500) {
                setCompanyVisible(false);
            } else {
                console.error('Unexpected error:', error);
            }
        }
    }

    const renderMainContent = () => {
        switch (hash) {
            case "#dashboard":
                return <DashboardComponent company={companyId} companyName={companyName} setCompanyAddress={setCompanyAddress} />                
            case '#locations':
                return (
                <CompanyLocationsEntryComponent company={companyId} /> 
                )      
            default:
                return (
                    <RelationshipComponent company={companyId} setCompanyAddress={setCompanyAddress} />
                )
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{
                top: 70, position: 'sticky',
                zIndex: 1000,
                backgroundColor: 'black',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
                {companyName != "" ?
                    <CompanyHeader company_name={companyName} company_address={companyAddress} />
                :
                    <></>}
            </div>

            <div className='customer-display-container'>
                {
                    (companyName != "" && companyVisible) ?
                        renderMainContent() :
                        <div style={{ height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            Company does not exist or is under investigation
                        </div>
                }
                <Footer />
            </div>
        </div>
    )
}

export default CompanyScreen;
