import React, { useState, useEffect } from 'react';

import CompanyLocations from '../DashboardComponent/CompanyLocations/CompanyLocations';
import LocationRelationshipComponent from './Relationships/RelationshipComponent';
import {getCompanyLocations} from '../../../services/company-details-service';

const CompanyLocationsEntryComponent = ({company}) => {
  const [locations, setCompanyLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
        try {
            const response = await getCompanyLocations(company);
            setCompanyLocations(response.data?.locations);
            setCompanyDetails(response?.data?.company)
        }
        catch (error) {
            console.log(error)
        }
    }
    const [selectedLocation, setSelectedLocation] = useState(null)
 

  return (
   <div>
         <CompanyLocations 
            companyDetails={companyDetails} 
            companyLocations={locations} 
            setSelectedLocation={setSelectedLocation}
         />
         {selectedLocation && (
            <>
                <LocationRelationshipComponent company={selectedLocation} />
            </>
         )}
   </div>
  );
};

export default CompanyLocationsEntryComponent;