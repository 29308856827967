import React from 'react';
import "./index.css";
import { Box, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
const ColorLegend = () => {
  const legendItems = [
    { color: 'black', description: 'Searched Entity' },
    { color: 'red', description: 'Potential Risk' },
    { color: 'orange', description: 'Direct (Tier-1) Connection to Potential Risk' },
    { color: 'yellow', description: 'Indirect (Tier-2+) Connection to Potential Risk' },
    { color: 'green', description: 'No evidence based on current data and filters' },
  ];
  return (
    <div className="flex flex-col items-center w-full p-4 bg-gray-100 rounded-lg">
        
        <List disablePadding>
        {legendItems.map((item, index) => (
          <ListItem key={index} disableGutters>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Box 
                sx={{ 
                  width: 16, 
                  height: 16, 
                  bgcolor: item.color, 
                  borderRadius: '50%'
                }} 
              />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Typography variant="body2">
                  {item.description}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {/* <div className="learn-more">
        To learn more about potential risk, please visit our SupplyTrace Resources section.
      </div> */}
    </div>
  );
};

export default ColorLegend;
