import { createAsyncThunk } from "@reduxjs/toolkit";
import {get_all_companies, get_company_details,getCustomerData,get_investigate_company_results, getSupplierData} from './company-details-service';



export const getCompanyDetailsThunk = createAsyncThunk(
    'get_company_details',
    async (details) => await get_company_details(details)
)

export const getAllCompaniesThunk = createAsyncThunk(
    'get_all_companies',
    async () => await get_all_companies()
)

export const getCompanyYearAndProducts = createAsyncThunk(
    'get_investigate+details',
    async () => await get_investigate_company_results()
)

export const getSupplierDataThunk = createAsyncThunk(
    'get_supplier_data',
    async (details) => await getSupplierData(details)
)

export const getCustomerDataThunk = createAsyncThunk(
    'get_customer_data',
    async (details) => await getCustomerData(details)
)

