import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from 'recharts';

const TopBarChartComponent = ({ is_supplier, data }) => (
    <BarChart
    width={450}
    height={440}
    data={data}
    barGap={2}
    barSize={55}
  >
    <XAxis
        dataKey={ is_supplier ? "supplier" : "customer"}
        tick={{ fontSize: 8, width: 25 }}
        name="Height"
        angle={20}
    />
    <YAxis />
    <Tooltip />
    {/* <Legend verticalAlign="top" height={36} /> */}
    <Bar dataKey="total_transactions" fill="#79CCB3" />
  </BarChart>
);



export default TopBarChartComponent;