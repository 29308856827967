import './index.css'
import { useEffect, useState } from "react";
import VisualisationFilterComponent from '../VisualisationFIlterComponent';
import RelationshipMapComponent from './MapComponents/RelationshipMapComponent';
import { Grid, Tooltip } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import './index.css';
import ColorLegend from './ColorLegend';
import RelationshipSankeyComponent from '../SankeyPlot/RelationshipSankeyComponent';
import RelationshipTable from './RelationshipTable';
import ThreeOptionToggle from './VisualOptions';

import { deepCopy, filterRelationsByCountry, fetchData, processEntityData } from './VisualisationHelpers';

const VisualisationsEntryComponent = ({allSuppliersData, allCustomersData, supplier_companies,customer_companies, company_name, is_customer }) => {
    const [suppliersLoading, setSuppliersLoading] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([])

    const [selectedTierFilter, setSelectedTierFilter] = useState("3");
    const [suppliersData, setSuppliersData] = useState(null);
    const [customersData, setCustomersData] = useState(null)   
 
    const [limitedSupplierData, setLimitedSupplierData] = useState(null); 
    const [limitedCustomerData, setLimitedCustomerData] = useState(null); 
    const [selected, setSelected] = useState('map');
    const processFilters = (filterQuery) => {
        try {
          var allSuppliersDataCurrent = deepCopy(allSuppliersData);
          var limitedSuppliersDataCurrent = deepCopy(limitedSupplierData);
          var allCustomersDataCurrent = deepCopy(allCustomersData);
          var limitedCustomersDataCurrent = deepCopy(limitedCustomerData);
          console.log(allSuppliersDataCurrent, allSuppliersData)
          if(filterQuery.countries.length > 0) {
            const l = filterRelationsByCountry(filterQuery?.countries ?? [], allSuppliersDataCurrent, limitedSuppliersDataCurrent);
            console.log(l, "filtered from suppliers by country")
            allSuppliersDataCurrent = l[0];
            limitedSuppliersDataCurrent = l[1];
            const k = filterRelationsByCountry(filterQuery?.countries ?? [], allCustomersDataCurrent, limitedCustomersDataCurrent);
            allCustomersDataCurrent = k[0];
            limitedCustomersDataCurrent = k[1];
          }
          if(filterQuery.suppliers.length > 0 && filterQuery.customers.length <= 0 && filterQuery.show_suppliers ) {
            fetchData(
                  'suppliers',
                  filterQuery,
                  company_name,
                  allSuppliersDataCurrent,
                  limitedSuppliersDataCurrent,
                  false,
                  setSuppliersLoading,
                  setSelectedSuppliers,
                  setSuppliersData,
                  setSelectedTierFilter
            );
            setCustomersData(null)
            console.log(suppliersData,"suppliers lol")
            
          } else if(filterQuery.suppliers.length <= 0 &&  filterQuery.customers.length < 0  && filterQuery.show_customers ) {

            fetchData(
              'customers',
              filterQuery,
              company_name,
              allCustomersDataCurrent,
              limitedCustomersDataCurrent,
              true,
              setSuppliersLoading,
              setSelectedCustomers,
              setCustomersData,
              setSelectedTierFilter
            );
            setSuppliersData(null)
          } else {
            console.log(limitedSuppliersDataCurrent, "beforesksks")
            if(filterQuery.show_suppliers) {
              fetchData(
                'suppliers',
                filterQuery,
                company_name,
                allSuppliersDataCurrent,
                limitedSuppliersDataCurrent,
                false,
                setSuppliersLoading,
                setSelectedSuppliers,
                setSuppliersData,
                setSelectedTierFilter
              );
            } else {
              setSuppliersData(null);
            }
            if(filterQuery.show_customers) {
              fetchData(
                'customers',
                filterQuery,
                company_name,
                allCustomersDataCurrent,
                limitedCustomersDataCurrent,
                true,
                setSuppliersLoading,
                setSelectedCustomers,
                setCustomersData,
                setSelectedTierFilter
              );
            } else {
              setCustomersData(null)
            }
            
          }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
      const suppliersData = processEntityData(allSuppliersData, false, selectedTierFilter);
      const customersData = processEntityData(allCustomersData, true, selectedTierFilter);
      setLimitedSupplierData(suppliersData);
      setLimitedCustomerData(customersData);
    }, [allSuppliersData, allCustomersData]);
    useEffect(()=> {
        const filterQuery = {
            company: company_name,
            product: "ALL",
            suppliers: [],
            customers: [],
            tiers: "3",
            is_sus: false,
            show_suppliers: true,
            show_customers: true,
            countries: []
        };
        processFilters(filterQuery);
    },[limitedSupplierData, limitedCustomerData]);
    const handleFilterSelection = (appliedFilter) => {
        const filterQuery = {
            company: company_name,
            product: "ALL",
            suppliers: appliedFilter.suppliers,
            customers: appliedFilter.customers,
            tiers: appliedFilter.tier,
            is_sus: appliedFilter.is_sus,
            show_suppliers: appliedFilter.show_suppliers,
            show_customers: appliedFilter.show_customers,
            countries: appliedFilter.countries
        }
        console.log(filterQuery, "query applying")
        processFilters(filterQuery);
    }
    const supplierCountries = new Set(
      allSuppliersData?.data.tiers.reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue.supplier_countries.split(",")
        ],
        []
      )
    );
    const customerCountries = new Set(
      allCustomersData?.data.tiers.reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue.supplier_countries.split(",")
        ],
        []
      )
    );
    const combinedCountries = new Set([...supplierCountries, ...customerCountries]);

    return (
        <div>
        {allSuppliersData?.data?.has_suppliers}
            {!suppliersLoading && ((allSuppliersData?.data?.has_suppliers) ||(allCustomersData?.data?.has_customers) )  && (
                    <div className='supplier-data-container' >
                        <div className='supplier-map'>

                            <div className='supplier-map-header-container'>
                                <div className='supplier-map-header-text'>
                                    Relationships {" "}
                                    <Tooltip
                                      title="This map is based off of relationships between entities, and does not necessarily represent the flow of a specific product between tiers"
                                      placement="right-start"
                                      arrow
                                      >
                                      <InfoIcon></InfoIcon>
                                    </Tooltip>
                                </div>
                                <div style={{marginLeft: 'auto', marginRight: '1rem'}}>
                                  <ThreeOptionToggle setSelected={setSelected} selected={selected}/>
                                </div>
                            </div>
                            <div style={{width: '100%', margin: '0px'}}>
                              <VisualisationFilterComponent
                                        numberOfTiers={Math.max(allSuppliersData?.data.tiers.length, allCustomersData?.data?.tiers?.length)}
                                        countries={[...combinedCountries]}
                                        supplier_companies={supplier_companies}
                                        customer_companies = {customer_companies}
                                        handleFilterSelection={handleFilterSelection}
                                        is_customer = {is_customer}
                              ></VisualisationFilterComponent>
                            </div>
                            {selected !== 'table' ? (
                            <div>      
                                                              
                                  <Grid container spacing={2} style={{marginTop: '1rem'}}>
                                      <Grid item xs={12} md={10} style={{ width: '70%' }}>
                                      {selected === 'map' ? (
                                        <RelationshipMapComponent
                                            supplier_details={suppliersData?.data}
                                            customer_details={customersData?.data}
                                            company_name={company_name}
                                            selectedTierFilter={selectedTierFilter}
                                            selectedSuppliers={selectedSuppliers}
                                            selectedCustomers={selectedCustomers}
                                        />):
                                        (<RelationshipSankeyComponent 
                                          supplier_details={suppliersData?.data} 
                                          customer_details={customersData?.data} 
                                          company_name={company_name} 
                                          is_customer={is_customer}  
                                          selectedTierFilter={selectedTierFilter}
                                        />)}
                                      </Grid>
                                      <Grid item xs={12} md={2} style={{ width: '30%' }}>
                                        <ColorLegend />
                                      </Grid>
                                    </Grid>

                            </div>):
                           
                            (<div>
                                  <div style={{margin: '2.5rem', width: '100%'}}>
                                    <RelationshipTable supplierDetails={suppliersData?.data} customerDetails={customersData?.data} />
                                  </div>
                                  
                            </div>)
                            }
                        </div>
                    </div>
            )}
        </div>
    );
};

export default VisualisationsEntryComponent;