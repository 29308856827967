import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    DataGrid
  } from "@mui/x-data-grid";
import { useSelector } from 'react-redux';
import { 
    Box, 
    Button,
    Popover,
    TextField,
    MenuItem,
  } from '@mui/material';
  import FilterListIcon from '@mui/icons-material/FilterList';
import { processDetails } from './VisualisationHelpers';
const PaginatedRelationTableComponent = ({ data}) => {
  const { currentUser } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues: [],
  });
  const [tempFilter, setTempFilter] = useState({
    field: '',
    operator: '',
    value: '',
  });

  const valueInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused && valueInputRef.current) {
      valueInputRef.current.focus();
    }
  }, [tempFilter, isFocused]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempFilter({
      field: filterModel.items[0]?.field || '',
      operator: filterModel.items[0]?.operator || '',
      value: filterModel.items[0]?.value || '',
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const columns = [
    // { field: 'fromCompanyId', headerName: 'From Company ID', flex: 1 },
    { field: 'fromCompanyName', headerName: 'Supplier', flex: 1 },
    // { field: 'toCompanyId', headerName: 'To Company ID', flex: 1 },
    { field: 'toCompanyName', headerName: 'Customer', flex: 1 },
    { field: 'totalTransactions', headerName: 'Estimated Transactions', flex: 1 }
];

  const rows = data.map((row, index) => ({
    id: index,
    ...row
  }));

  const handleTempFilterChange = (field, value) => {
    setTempFilter(prev => ({ ...prev, [field]: value }));
    if (field === 'value') {
      setIsFocused(true);
    }
  };

  const handleApplyFilter = () => {
    if (tempFilter.field && tempFilter.operator && tempFilter.value) {
      setFilterModel({
        ...filterModel,
        items: [tempFilter],
      });
    } else {
      setFilterModel({
        ...filterModel,
        items: [],
      });
    }
    handleClose();
  };

  const CustomFilterComponent = () => (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        select
        label="Field"
        value={tempFilter.field}
        onChange={(e) => handleTempFilterChange('field', e.target.value)}
      >
        {columns.map((column) => (
          <MenuItem key={column.field} value={column.field}>
            {column.headerName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Operator"
        value={tempFilter.operator}
        onChange={(e) => handleTempFilterChange('operator', e.target.value)}
      >
        <MenuItem value="contains">Contains</MenuItem>
        <MenuItem value="equals">Equals</MenuItem>
        <MenuItem value="startsWith">Starts with</MenuItem>
        <MenuItem value="endsWith">Ends with</MenuItem>
      </TextField>
      <TextField
        label="Value"
        value={tempFilter.value}
        onChange={(e) => handleTempFilterChange('value', e.target.value)}
        inputRef={valueInputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleApplyFilter}
      >
        Apply Filter
      </Button>
    </Box>
  );

  return (
    <div className='evidences-container'>
        <Box sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="flex-end" width="100%" margin={'10px 0px'}>
                
                <Box>
                    <Button 
                        onClick={handleClick}
                        startIcon={<FilterListIcon />} 
                        sx={{ mr: 1, color: 'black', border: '1px solid black'}}
                    >
                        Filter
                    </Button>

                </Box>
                
            </Box>
            <Box>
                <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'} 
                autoHeight
                disableSelectionOnClick
                disableRowSelectionOnClick
                checkboxSelection={false}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                initialState={{
                    pagination: {
                    paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                pageSizeOptions={[5, 10, 25]}
                sx={{
                    '& .super-app-theme--header': {
                    backgroundColor: 'white',
                    fontWeight: 'bold',
                    fontSize: 16,
                    },
                    '& .super-app-theme--header': {
                    // backgroundColor: 'white',
                    fontWeight: 'bold',
                    fontSize: 16,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                    border: '1px solid black',
                    },
                    '& .MuiDataGrid-columnHeader': {
                    borderRight: '1px solid black',
                    },
                
                    '& .MuiDataGrid-cell': {
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black',
                    },
                    '& .MuiDataGrid-cell:first-of-type': {
                    borderLeft: '1px solid black',
                    },

                    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                    borderBottom: 'none',
                    },
                    '& .MuiDataGrid-footerContainer': {
                    borderTop: '1px solid black',
                    },
                }}
                />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                <CustomFilterComponent />
            </Popover>
        </Box>
    </div>
    
  );
};

const RelationshipTable = ({ supplierDetails, customerDetails }) => {
    const relationshipsTable = processDetails(supplierDetails, customerDetails);
    const tableData = relationshipsTable.map(link => ({
        id: link.id,
        fromCompanyId: link.fromCompanyId,
        fromCompanyName: link.fromCompanyName,
        toCompanyId: link.toCompanyId,
        toCompanyName: link.toCompanyName,
        totalTransactions: link.totalTransactions
    }));

    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    const pageCount = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentData = useMemo(() => {
        const startIndex = currentPage * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return tableData.slice(startIndex, endIndex);
    }, [tableData, currentPage, rowsPerPage]);

    return (
        <PaginatedRelationTableComponent
            data={tableData}
        />
    );
};

export default RelationshipTable;
